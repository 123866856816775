<template>
  <div>
    <div class="">
      <div @click="show_temp = !show_temp">
        <input-text
          type="text"
          placeholder="Select Unit"
          readonly
          v-model="temp_selected"
        ></input-text>
        <div class="relative">
          <a href="javascript:;">
            <selectarrow-icon
              class="absolute right-0 -mt-8 mr-2"
              :width="'w-4'"
              :height="'h-4'"
              :color="'text-primary'"
            ></selectarrow-icon>
          </a>
        </div>
      </div>
      <transition name="localform">
        <div class="relative" v-if="show_temp">
          <div
            class="
              bg-white
              absolute
              z-30
              rounded
              mt-1
              w-full
              border-t
              shadow-md
            "
          >
            <div class="overflow-y-scroll max-h-44">
              <p
                class="px-3 py-2 hover:bg-gray-300"
                :class="{
                  'rounded-t': index == 0,
                  'rounded-b': index + 1 == temps.length
                }"
                @click="selectTemp(index)"
                v-for="(temp, index) in temps"
                :key="index"
              >
                {{ temp.name }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SelectarrowIcon from '../Icons/selectarrowIcon.vue'
import InputText from './InputText.vue'
export default {
  components: { InputText, SelectarrowIcon },
  props: {
    unit_from_db: String
  },
  data() {
    return {
      temps: [
        { name: 'celcius', id: 1 },
        { name: 'fahrenheit', id: 2 }
      ],
      show_temp: false,
      temp_selected: ''
    }
  },
  watch: {
    temp_selected(val) {
      this.$emit('add-unit', val)
    },
    unit_from_db(val) {
      if (val != undefined) {
        this.temp_selected = val
      }
    }
  },
  methods: {
    selectTemp(val) {
      this.temp_selected = this.temps[val].name
      this.show_temp = false
    }
  },
  mounted() {
    if (this.unit_from_db != undefined) {
      this.temp_selected = this.unit_from_db
    }
  }
}
</script>

<style>
.localform-enter-active,
.localform-leave-active {
  transition: transition ease-in, opacity ease-in-out;
  @apply duration-300;
}

.localform-enter,
.localform-leave-to {
  opacity: 0;
}

.localform-leave,
.localform-enter-to {
  opacity: 1;
}
</style>
