<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 xl:w-2/3 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">
              Discharge Report
            </div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="py-20 sm:w-4/5 mx-auto">
              <div class="sm:w-3/5 sm:mx-auto space-y-5">
                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Diagnosis</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.diagnosis"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                        focus:border-primary
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Treatment/Test</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.treatment_test"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Medications</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.medications"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Exercise</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.exercise"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Diatory Directions</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.diatory_directions"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Check Up</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.check_up"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Discharge Note</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.discharge_note"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>

                <div
                  class="
                    sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start
                    space-y-2
                    sm:space-y-0
                  "
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Vaterinrian</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="discharge.vaterinrian"
                      placeholder="Note"
                      cols="30"
                      rows="3"
                      class="
                        w-full
                        border border-gray-300
                        px-4
                        focus:border-primary
                        py-2
                        outline-none
                        rounded-md
                        text-lg
                      "
                    ></textarea>
                  </div>
                </div>
              </div>
            </section>
            <section class="flex justify-center" v-if="index == 1">
              <button
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
                @click="saveDoc"
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../../Icons/closeIcon.vue'
export default {
  components: { closeIcon },

  props: {
    report_id: String,

    index: [Number, String],

    report: [Object, Array]
  },

  data() {
    return {
      discharge: {
        diagnosis: null,
        treatment_test: null,
        medications: null,
        exercise: null,
        diatory_directions: null,
        check_up: null,
        discharge_note: null,
        vaterinrian: null
      }
    }
  },

  watch: {
    diagnosis(val) {
      if (val != undefined) {
        this.discharge.diagnosis = val
      }
    },

    treatment_test(val) {
      if (val != undefined) {
        this.discharge.treatment_test = val
      }
    },

    medications(val) {
      if (val != undefined) {
        this.discharge.medications = val
      }
    },

    exercise(val) {
      if (val != undefined) {
        this.discharge.exercise = val
      }
    },

    diatory_directions(val) {
      if (val != undefined) {
        this.discharge.diatory_directions = val
      }
    },

    check_up(val) {
      if (val != undefined) {
        this.discharge.check_up = val
      }
    },

    discharge_note(val) {
      if (val != undefined) {
        this.discharge.discharge_note = val
      }
    },

    vaterinrian(val) {
      if (val != undefined) {
        this.discharge.vaterinrian = val
      }
    },

    report(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.discharge != undefined
      ) {
        if (val.medical.discharge.diagnosis != undefined) {
          this.discharge.diagnosis = val.medical.discharge.diagnosis
        }

        if (val.medical.discharge.treatment_test != undefined) {
          this.discharge.treatment_test = val.medical.discharge.treatment_test
        }

        if (val.medical.discharge.medications != undefined) {
          this.discharge.medications = val.medical.discharge.medications
        }

        if (val.medical.discharge.exercise != undefined) {
          this.discharge.exercise = val.medical.discharge.exercise
        }

        if (val.medical.discharge.diatory_directions != undefined) {
          this.discharge.diatory_directions =
            val.medical.discharge.diatory_directions
        }

        if (val.medical.discharge.check_up != undefined) {
          this.discharge.check_up = val.medical.discharge.check_up
        }

        if (val.medical.discharge.discharge_note != undefined) {
          this.discharge.discharge_note = val.medical.discharge.discharge_note
        }

        if (val.medical.discharge.vaterinrian != undefined) {
          this.discharge.vaterinrian = val.medical.discharge.vaterinrian
        }
      }
    }
  },
  methods: {
    saveDoc() {
      var discharge = {
        id: this.report_id,
        diagnosis: this.discharge.diagnosis,
        treatment_test: this.discharge.treatment_test,
        medications: this.discharge.medications,
        exercise: this.discharge.exercise,
        diatory_directions: this.discharge.diatory_directions,
        check_up: this.discharge.check_up,
        discharge_note: this.discharge.discharge_note,
        vaterinrian: this.discharge.vaterinrian
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('medical_discharge/update', discharge)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report != undefined &&
      this.report.medical != undefined &&
      this.report.medical.discharge != undefined
    ) {
      if (this.report.medical.discharge.diagnosis != undefined) {
        this.discharge.diagnosis = this.report.medical.discharge.diagnosis
      }

      if (this.report.medical.discharge.treatment_test != undefined) {
        this.discharge.treatment_test =
          this.report.medical.discharge.treatment_test
      }

      if (this.report.medical.discharge.medications != undefined) {
        this.discharge.medications = this.report.medical.discharge.medications
      }

      if (this.report.medical.discharge.exercise != undefined) {
        this.discharge.exercise = this.report.medical.discharge.exercise
      }

      if (this.report.medical.discharge.diatory_directions != undefined) {
        this.discharge.diatory_directions =
          this.report.medical.discharge.diatory_directions
      }

      if (this.report.medical.discharge.check_up != undefined) {
        this.discharge.check_up = this.report.medical.discharge.check_up
      }

      if (this.report.medical.discharge.discharge_note != undefined) {
        this.discharge.discharge_note =
          this.report.medical.discharge.discharge_note
      }

      if (this.report.medical.discharge.vaterinrian != undefined) {
        this.discharge.vaterinrian = this.report.medical.discharge.vaterinrian
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
