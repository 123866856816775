<template>
  <section class="bg-white px-4 sm:px-8 pt-6 pb-10 rounded shadow mt-6">
    <div class="float-right">
      <!-- <h1 class="text-primary text-2xl">{{ report.medical[0].length }} records</h1> -->
    </div>
    <div class="pt-16 sm:px-10 space-y-4">
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('referred-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <div>
            <span v-if="referred_is_empty">Add</span>
            <span v-if="referred_is_not_empty">View</span> Referred
          </div>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="referred_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="referred_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Referred</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('chief-compliant-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="chief_compliant_is_empty">Add</span>
            <span v-if="chief_compliant_is_not_empty">View</span> Chief
            Compliant
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="chief_compliant_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="chief_compliant_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Chief Compliant</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('medical-history-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="medical_history_is_empty">Add</span>
            <span v-if="medical_history_is_not_empty">View</span> Medical
            History
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="medical_history_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="medical_history_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Medical History</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('physical-report-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="physical_exam_is_empty">Add</span>
            <span v-if="physical_exam_is_not_empty">View</span> Physical
            Examination
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="physical_exam_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="physical_exam_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Physical Examination</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('diagnosis-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="diagnosis_is_empty">Add</span>
            <span v-if="diagnosis_is_not_empty">View</span> Diagnosis
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="diagnosis_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="diagnosis_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Diagnosis</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('discharge-modal')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="discharge_is_empty">Add</span>
            <span v-if="discharge_is_not_empty">View</span> Discharge
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="discharge_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="discharge_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Discharge</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
        @click="$emit('submitted-by')"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="submitted_by_is_empty">Add</span>
            <span v-if="submitted_by_is_not_empty">View</span> Submitted By
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="submitted_by_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="submitted_by_is_not_empty"
          ></view-icon>
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Submitted By</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import addIcon from '../../Icons/addIcon.vue'
import ViewIcon from '../../Icons/viewIcon.vue'
export default {
  components: { addIcon, ViewIcon },

  props: {
    report_id: String,
    index: [Number, String]
  },

  data() {
    return {
      referred_is_not_empty: false,
      referred_is_empty: false,
      chief_compliant_is_not_empty: false,
      chief_compliant_is_empty: false,
      medical_history_is_not_empty: false,
      medical_history_is_empty: false,
      physical_exam_is_empty: false,
      physical_exam_is_not_empty: false,
      diagnosis_is_not_empty: false,
      diagnosis_is_empty: false,
      discharge_is_empty: false,
      discharge_is_not_empty: false,
      submitted_by_is_empty: false,
      submitted_by_is_not_empty: false
    }
  },

  computed: {
    ...mapState('reports', ['report'])
  },

  watch: {
    report(val) {
      if (val != undefined && val.medical != undefined) {
        if (val.medical.referred != undefined) {
          this.referred_is_not_empty = true
          this.referred_is_empty = false
        } else {
          this.referred_is_empty = true
          this.referred_is_not_empty = false
        }

        if (val.medical.chief_compliant != undefined) {
          this.chief_compliant_is_not_empty = true
          this.chief_compliant_is_empty = false
        } else {
          this.chief_compliant_is_empty = true
          this.chief_compliant_is_not_empty = false
        }

        if (val.medical.physical_examination != undefined) {
          this.physical_exam_is_not_empty = true
          this.physical_exam_is_empty = false
        } else {
          this.physical_exam_is_empty = true
          this.physical_exam_is_not_empty = false
        }

        if (val.medical.medical_history != undefined) {
          this.medical_history_is_not_empty = true
          this.medical_history_is_empty = false
        } else {
          this.medical_history_is_empty = true
          this.medical_history_is_not_empty = false
        }

        if (val.medical.diagnosis != undefined) {
          this.diagnosis_is_not_empty = true
          this.diagnosis_is_empty = false
        } else {
          this.diagnosis_is_empty = true
          this.diagnosis_is_not_empty = false
        }

        if (val.medical.discharge != undefined) {
          this.discharge_is_not_empty = true
          this.discharge_is_empty = false
        } else {
          this.discharge_is_empty = true
          this.discharge_is_not_empty = false
        }

        if (val.medical.submitted_by != undefined) {
          this.submitted_by_is_not_empty = true
          this.submitted_by_is_empty = false
        } else {
          this.submitted_by_is_empty = true
          this.submitted_by_is_not_empty = false
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch('reports/getReport', this.report_id)

    if (this.report != undefined && this.report.medical != undefined) {
      if (this.report.medical.referred != undefined) {
        this.referred_is_not_empty = true
        this.referred_is_empty = false
      } else {
        this.referred_is_empty = true
        this.referred_is_not_empty = false
      }

      if (this.report.medical.chief_compliant != undefined) {
        this.chief_compliant_is_not_empty = true
        this.chief_compliant_is_empty = false
      } else {
        this.chief_compliant_is_empty = true
        this.chief_compliant_is_not_empty = false
      }

      if (this.report.medical.physical_examination != undefined) {
        this.physical_exam_is_not_empty = true
        this.physical_exam_is_empty = false
      } else {
        this.physical_exam_is_empty = true
        this.physical_exam_is_not_empty = false
      }

      if (this.report.medical.medical_history != undefined) {
        this.medical_history_is_not_empty = true
        this.medical_history_is_empty = false
      } else {
        this.medical_history_is_empty = true
        this.medical_history_is_not_empty = false
      }

      if (this.report.medical.diagnosis != undefined) {
        this.diagnosis_is_not_empty = true
        this.diagnosis_is_empty = false
      } else {
        this.diagnosis_is_empty = true
        this.diagnosis_is_not_empty = false
      }

      if (this.report.medical.lab_test_result != undefined) {
        this.lab_test_result_is_not_empty = true
        this.lab_test_result_is_empty = false
      } else {
        this.lab_test_result_is_empty = true
        this.lab_test_result_is_not_empty = false
      }

      if (this.report.medical.discharge != undefined) {
        this.discharge_is_not_empty = true
        this.discharge_is_empty = false
      } else {
        this.discharge_is_empty = true
        this.discharge_is_not_empty = false
      }

      if (this.report.medical.submitted_by != undefined) {
        this.submitted_by_is_not_empty = true
        this.submitted_by_is_empty = false
      } else {
        this.submitted_by_is_empty = true
        this.submitted_by_is_not_empty = false
      }
    }
  }
}
</script>

<style></style>
