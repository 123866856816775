<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">Referred By</div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="py-20 space-y-6 sm:w-4/5 sm:mx-auto">
              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Veterinarian:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="Veterinarian"
                    v-model="referred.veterinarian"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Consultant:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="Consultant"
                    v-model="referred.consultant"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Clinician:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="Clinician"
                    v-model="referred.clinician"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Resident Veterinarian:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="Resident Veterinarian"
                    v-model="referred.resident_veterinarian"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Student:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="Student"
                    v-model="referred.student"
                  ></input-text>
                </div>
              </div>
            </section>

            <section class="flex justify-center" v-if="index == 1">
              <button
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
                @click="saveDoc"
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../../Icons/closeIcon.vue'
import InputText from '../../Utilities/InputText.vue'
export default {
  components: { closeIcon, InputText },
  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },

  data() {
    return {
      report_from_db: this.report,
      referred: {
        veterinarian: null,
        consultant: null,
        clinician: null,
        resident_veterinarian: null,
        student: null
      }
    }
  },

  watch: {
    veterinarian(val) {
      if (val == '') {
        this.referred.veterinarian = null
      }
    },

    consultant(val) {
      if (val == '') {
        this.referred.consultant = null
      }
    },

    clinician(val) {
      if (val == '') {
        this.referred.clinician = null
      }
    },

    resident_veterinarian(val) {
      if (val == '') {
        this.referred.resident_veterinarian = null
      }
    },

    student(val) {
      if (val == '') {
        this.referred.student = null
      }
    },

    report_from_db(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.referred != undefined
      ) {
        if (val.medical.referred.veterinarian != undefined) {
          this.referred.veterinarian = val.medical.referred.veterinarian
        }

        if (val.medical.referred.consultant != undefined) {
          this.referred.consultant = val.medical.referred.consultant
        }

        if (val.medical.referred.clinician != undefined) {
          this.referred.clinician = val.medical.referred.clinician
        }

        if (val.medical.referred.resident_veterinarian != undefined) {
          this.referred.resident_veterinarian =
            val.medical.referred.resident_veterinarian
        }

        if (val.medical.referred.student != undefined) {
          this.referred.student = val.medical.referred.student
        }
      }
    }
  },

  methods: {
    saveDoc() {
      var referred = {
        id: this.report_id,
        veterinarian: this.referred.veterinarian,
        consultant: this.referred.consultant,
        clinician: this.referred.clinician,
        resident_veterinarian: this.referred.resident_veterinarian,
        student: this.referred.student
      }

      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('medical_referred/update', referred)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report_from_db != undefined &&
      this.report_from_db.medical != undefined &&
      this.report_from_db.medical.referred != undefined
    ) {
      if (this.report_from_db.medical.referred.veterinarian != undefined) {
        this.referred.veterinarian =
          this.report_from_db.medical.referred.veterinarian
      }

      if (this.report_from_db.medical.referred.consultant != undefined) {
        this.referred.consultant =
          this.report_from_db.medical.referred.consultant
      }

      if (this.report_from_db.medical.referred.clinician != undefined) {
        this.referred.clinician = this.report_from_db.medical.referred.clinician
      }

      if (
        this.report_from_db.medical.referred.resident_veterinarian != undefined
      ) {
        this.referred.resident_veterinarian =
          this.report_from_db.medical.referred.resident_veterinarian
      }

      if (this.report_from_db.medical.referred.student != undefined) {
        this.referred.student = this.report_from_db.medical.referred.student
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
