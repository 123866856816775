<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">Submitted By</div>
            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="py-20 space-y-6 sm:w-4/5 sm:mx-auto">
              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg">Veterinarian:</h1>
                </div>

                <div class="col-span-3">
                  <input-text
                    placeholder="Veterinarian"
                    v-model="submitted_by.veterinarian"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg">Consultant:</h1>
                </div>

                <div class="col-span-3">
                  <input-text
                    placeholder="Consultant"
                    v-model="submitted_by.consultant"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg">Clinician:</h1>
                </div>

                <div class="col-span-3">
                  <input-text
                    placeholder="Clinician"
                    v-model="submitted_by.clinician"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg">Student:</h1>
                </div>

                <div class="col-span-3">
                  <input-text
                    placeholder="Veterinarian"
                    v-model="submitted_by.student"
                  ></input-text>
                </div>
              </div>
            </section>

            <section class="flex justify-center" v-if="index == 1">
              <button
                @click="saveDoc"
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../../Icons/closeIcon.vue'
import InputText from '../../Utilities/InputText.vue'
export default {
  components: { closeIcon, InputText },

  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },

  data() {
    return {
      submitted_by: {
        veterinarian: null,
        consultant: null,
        clinician: null,
        student: null
      }
    }
  },

  watch: {
    veterinarian(val) {
      if (val != undefined) {
        this.submitted_by.veterinarian = val
      }
    },
    consultant(val) {
      if (val != undefined) {
        this.submitted_by.consultant = val
      }
    },
    clinician(val) {
      if (val != undefined) {
        this.submitted_by.clinician = val
      }
    },
    student(val) {
      if (val != undefined) {
        this.submitted_by.student = val
      }
    },
    report(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.submitted_by != undefined
      ) {
        if (val.medical.submitted_by.veterinarian != undefined) {
          this.submitted_by.veterinarian = val.medical.submitted_by.veterinarian
        }
        if (val.medical.submitted_by.consultant != undefined) {
          this.submitted_by.consultant = val.medical.submitted_by.consultant
        }
        if (val.medical.submitted_by.clinician != undefined) {
          this.submitted_by.clinician = val.medical.submitted_by.clinician
        }
        if (val.medical.submitted_by.student != undefined) {
          this.submitted_by.student = val.medical.submitted_by.student
        }
      }
    }
  },

  methods: {
    saveDoc() {
      var submitted_by = {
        id: this.report_id,
        veterinarian: this.submitted_by.veterinarian,
        consultant: this.submitted_by.consultant,
        clinician: this.submitted_by.clinician,
        student: this.submitted_by.student
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('medical_submitted_by/update', submitted_by)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report != undefined &&
      this.report.medical != undefined &&
      this.report.medical.submitted_by != undefined
    ) {
      if (this.report.medical.submitted_by.veterinarian != undefined) {
        this.submitted_by.veterinarian =
          this.report.medical.submitted_by.veterinarian
      }
      if (this.report.medical.submitted_by.consultant != undefined) {
        this.submitted_by.consultant =
          this.report.medical.submitted_by.consultant
      }
      if (this.report.medical.submitted_by.clinician != undefined) {
        this.submitted_by.clinician = this.report.medical.submitted_by.clinician
      }
      if (this.report.medical.submitted_by.student != undefined) {
        this.submitted_by.student = this.report.medical.submitted_by.student
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
