<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 sm:w-3/4 md:w-4/5 lg:w-2/3 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">
              Bite Victim Information
            </div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section
              class="py-20 sm:w-full md:w-4/5 lg:w-4/5 xl:w-1/2 sm:mx-auto"
            >
              <div class="space-y-6">
                <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Name:</h1>
                  </div>

                  <div class="col-span-3">
                    <input-text
                      type="text"
                      placeholder="Name"
                      v-model="animal_bite.name"
                    ></input-text>
                  </div>
                </div>
                <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Age:</h1>
                  </div>

                  <div class="col-span-3">
                    <input-text
                      type="number"
                      placeholder="Age"
                      v-model="animal_bite.age"
                    ></input-text>
                  </div>
                </div>

                <div
                  class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  v-if="under_18"
                >
                  <div class="col-span-1">
                    <h1 class="text-lg">Parents Name:</h1>
                  </div>

                  <div class="col-span-3">
                    <input-text
                      type="text"
                      placeholder="Parents Name"
                      v-model="animal_bite.parent_name"
                    ></input-text>
                  </div>
                </div>

                <div class="space-y-6">
                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Gender:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-gender
                        v-on:add-gender="Gender"
                        :pet_gender_db="gender_db"
                      ></input-gender>
                    </div>
                  </div>

                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Address:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-text
                        type="text"
                        placeholder="Address"
                        v-model="animal_bite.address"
                      ></input-text>
                    </div>
                  </div>

                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Community:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-text
                        type="text"
                        placeholder="Community"
                        v-model="animal_bite.community"
                      ></input-text>
                    </div>
                  </div>

                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Ward:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-text
                        type="email"
                        placeholder="Ward"
                        v-model="animal_bite.ward"
                      ></input-text>
                    </div>
                  </div>

                  <state-lga
                    v-on:state-lga="stateLga"
                    :state_from_db="animal_bite.state"
                    :local_govt_from_db="animal_bite.local_govt"
                  ></state-lga>

                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Telephone:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-text
                        type="number"
                        placeholder="Telephone"
                        v-model="animal_bite.telephone"
                      ></input-text>
                    </div>
                  </div>

                  <div
                    class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center"
                  >
                    <div class="col-span-1">
                      <h1 class="text-lg">Email:</h1>
                    </div>

                    <div class="col-span-3">
                      <input-text
                        type="email"
                        placeholder="Email"
                        v-model="animal_bite.email"
                      ></input-text>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="flex justify-center" v-if="index == 1">
              <button
                @click="saveDoc"
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../../Icons/closeIcon.vue'
import InputGender from '../../Utilities/InputGender.vue'
import InputText from '../../Utilities/InputText.vue'
import StateLga from '../../Utilities/StateLga.vue'
export default {
  components: { closeIcon, StateLga, InputText, InputGender },
  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      under_18: false,
      gender_db: null,
      animal_bite: {
        name: null,
        age: null,
        parent_name: null,
        gender: null,
        address: null,
        community: null,
        ward: null,
        state: null,
        local_govt: null,
        telephone: null,
        email: null
      }
    }
  },

  watch: {
    name(val) {
      if (val == '') {
        this.animal_bite.name = null
      }
    },

    age(val) {
      if (val == '') {
        this.animal_bite.age = null
      }
    },

    parent_name(val) {
      if (val == '') {
        this.animal_bite.parent_name = null
      }
    },

    address(val) {
      if (val == '') {
        this.animal_bite.address = null
      }
    },

    community(val) {
      if (val == '') {
        this.animal_bite.community = null
      }
    },

    ward(val) {
      if (val == '') {
        this.animal_bite.ward = null
      }
    },

    data(val) {
      if (val == '') {
        this.animal_bite.name = null
      }
    },

    state(val) {
      if (val == '') {
        this.animal_bite.state = null
      }
    },

    local_govt(val) {
      if (val == '') {
        this.animal_bite.local_govt = null
      }
    },

    telephone(val) {
      if (val == '') {
        this.animal_bite.telephone = null
      }
    },

    email(val) {
      if (val == '') {
        this.animal_bite.email = null
      }
    },

    gender(val) {
      if (val == '') {
        this.animal_bite.gender = null
        this.gender_db = null
      }
    },

    report(val) {
      if (
        val != undefined &&
        val.others != undefined &&
        val.others.animal_bite != undefined
      ) {
        if (val.others.animal_bite.name != undefined) {
          this.animal_bite.name = val.others.animal_bite.name
        }

        if (val.others.animal_bite.age != undefined) {
          this.animal_bite.age = val.others.animal_bite.age
        }

        if (val.others.animal_bite.parent_name != undefined) {
          this.animal_bite.parent_name = val.others.animal_bite.parent_name
        }

        if (val.others.animal_bite.gender != undefined) {
          this.animal_bite.gender = val.others.animal_bite.gender
          this.gender_db = val.others.animal_bite.gender
        }

        if (val.others.animal_bite.address != undefined) {
          this.animal_bite.address = val.others.animal_bite.address
        }

        if (val.others.animal_bite.community != undefined) {
          this.animal_bite.community = val.others.animal_bite.community
        }

        if (val.others.animal_bite.ward != undefined) {
          this.animal_bite.ward = val.others.animal_bite.ward
        }

        if (val.others.animal_bite.state != undefined) {
          this.animal_bite.state = val.others.animal_bite.state
        }

        if (val.others.animal_bite.local_govt != undefined) {
          this.animal_bite.local_govt = val.others.animal_bite.local_govt
        }

        if (val.others.animal_bite.telephone != undefined) {
          this.animal_bite.telephone = val.others.animal_bite.telephone
        }

        if (val.others.animal_bite.email != undefined) {
          this.animal_bite.email = val.others.animal_bite.email
        }
      }
    }
  },

  methods: {
    Gender(val) {
      if (val != undefined) {
        this.animal_bite.gender = val
      }
    },

    stateLga(val) {
      this.animal_bite.state = val.state
      this.animal_bite.local_govt = val.local_govt
    },

    saveDoc() {
      var value = {
        id: this.report_id,
        name: this.animal_bite.name,
        age: this.animal_bite.age,
        parent_name: this.animal_bite.parent_name,
        gender: this.animal_bite.gender,
        address: this.animal_bite.address,
        community: this.animal_bite.community,
        ward: this.animal_bite.ward,
        state: this.animal_bite.state,
        local_govt: this.animal_bite.local_govt,
        telephone: this.animal_bite.telephone,
        email: this.animal_bite.email
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('others_animal_bite/update', value)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 1500,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },
  mounted() {
    if (
      this.report != undefined &&
      this.report.others != undefined &&
      this.report.others.animal_bite != undefined
    ) {
      if (this.report.others.animal_bite.name != undefined) {
        this.animal_bite.name = this.report.others.animal_bite.name
      }

      if (this.report.others.animal_bite.age != undefined) {
        this.animal_bite.age = this.report.others.animal_bite.age
      }

      if (this.report.others.animal_bite.parent_name != undefined) {
        this.animal_bite.parent_name =
          this.report.others.animal_bite.parent_name
      }

      if (this.report.others.animal_bite.gender != undefined) {
        this.animal_bite.gender = this.report.others.animal_bite.gender
        this.gender_db = this.report.others.animal_bite.gender
      }

      if (this.report.others.animal_bite.address != undefined) {
        this.animal_bite.address = this.report.others.animal_bite.address
      }

      if (this.report.others.animal_bite.community != undefined) {
        this.animal_bite.community = this.report.others.animal_bite.community
      }

      if (this.report.others.animal_bite.ward != undefined) {
        this.animal_bite.ward = this.report.others.animal_bite.ward
      }

      if (this.report.others.animal_bite.state != undefined) {
        this.animal_bite.state = this.report.others.animal_bite.state
      }

      if (this.report.others.animal_bite.local_govt != undefined) {
        this.animal_bite.local_govt = this.report.others.animal_bite.local_govt
      }

      if (this.report.others.animal_bite.telephone != undefined) {
        this.animal_bite.telephone = this.report.others.animal_bite.telephone
      }

      if (this.report.others.animal_bite.email != undefined) {
        this.animal_bite.email = this.report.others.animal_bite.email
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
