<template>
  <main>
    <section class="flex justify-between align-middle">
      <div>
        <div v-if="index == 0" class="cursor-pointer">
          <span
            @click="backToReportHistory"
            class="flex space-x-2 text-primary mb-6"
          >
            <arrowback-icon></arrowback-icon>
            <span>back</span>
          </span>
        </div>
        <div v-if="index == 1" class="cursor-pointer">
          <span
            @click="backToClientProfile"
            class="flex space-x-2 text-primary mb-6"
          >
            <arrowback-icon></arrowback-icon>
            <span>back</span>
          </span>
        </div>
      </div>
      <div v-if="index == 1">
        <delete-report
          :pet_id="pet_id"
          :report_id="report_id"
          :client_id="client_id"
        ></delete-report>
      </div>
    </section>

    <section>
      <report-pet-profile :pet_id="pet_id"></report-pet-profile>
    </section>

    <section class="mt-12">
      <report-nav
        v-on:active-button="activeBtn"
        :report_id="report_id"
        :pet_id="pet_id"
        :index="index"
      ></report-nav>

      <section class="lg:grid lg:grid-cols-3 lg:gap-x-10">
        <section class="col-span-2">
          <medical-report-tab
            :index="index"
            :report_id="report_id"
            v-if="medical"
            @physical-report-modal="physicalModal()"
            @referred-modal="referredModal()"
            @chief-compliant-modal="chiefCompliant()"
            @medical-history-modal="medicalHistory()"
            @diagnosis-modal="diagnosis()"
            @discharge-modal="dischargeModal()"
            @submitted-by="submittedBy()"
          ></medical-report-tab>

          <lab-report-tab
            :index="index"
            :report_id="report_id"
            v-if="laboratory"
            @lab-test-modal="labTest()"
            @submitted-by="submittedBy()"
          ></lab-report-tab>

          <others-report-tab
            :index="index"
            :report_id="report_id"
            v-if="others"
            @animal-bite-modal="animalBite()"
          ></others-report-tab>
        </section>

        <section class="col-span-1 bg-white shadow rounded mt-6 px-3 py-4">
          <div class="border-b border-gray-100 px-2 sm:px-5">
            <div class="flex space-x-4">
              <p class="text-lg font-medium">Critical</p>
              <span>
                <warning-icon
                  :color="'text-red-500'"
                  :width="'w-6'"
                  :height="'h-6'"
                ></warning-icon
              ></span>
            </div>
            <div class="flex items-center justify-between py-2">
              <p class="text-lg font-medium">Haematology Test</p>
              <p class="text-gray-500">6,345 records</p>
            </div>
          </div>
          <div class="px-2 sm:px-5 py-3 space-y-2 border-b border-gray-100">
            <p class="text-gray-400">Location</p>
            <div class="flex space-x-2">
              <div class="py-1 px-3 bg-gray-100 rounded-full font-medium">
                lagos
              </div>
              <div class="py-1 px-3 bg-gray-100 rounded-full font-medium">
                Enugu
              </div>
              <div class="py-1 px-3 bg-gray-100 rounded-full font-medium">
                Anambra
              </div>
            </div>
          </div>
          <div class="px-2 sm:px-5 py-3 space-y-1 content">
            <p class="text-gray-400">Prevention</p>
            <div class="">
              Staphylococcus is a genus of Gram-positive bacteria in the family
              Staphylococcaceae in the order Bacillales. Under the microscope,
              they appear spherical (cocci), and form in grape-like clusters
            </div>
          </div>
          <div class="mt-2 mb-3 flex justify-center">
            <button
              class="py-2 px-4 bg-gray-100 rounded shadow hover:shadow-md"
            >
              Continue reading
            </button>
          </div>
        </section>
      </section>
    </section>

    <section>
      <physical-exam-modal
        v-if="show_physical_modal"
        @close-modal="physicalModal()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></physical-exam-modal>

      <referred-modal
        v-if="referred_modal"
        @close-modal="referredModal()"
        :report_id="report_id"
        :report="report"
        :index="index"
      >
      </referred-modal>

      <chief-compliant-modal
        v-if="chief_compliant_modal"
        @close-modal="chiefCompliant()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></chief-compliant-modal>

      <medical-history-modal
        v-if="medical_history_modal"
        @close-modal="medicalHistory()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></medical-history-modal>

      <diagnosis-modal
        v-if="diagnosis_report"
        @close-modal="diagnosis()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></diagnosis-modal>

      <discharge-modal
        v-if="discharge_modal"
        @close-modal="dischargeModal()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></discharge-modal>

      <submitted-by
        v-if="submitted_by"
        @close-modal="submittedBy()"
        :report_id="report_id"
        :report="report"
        :index="index"
      >
      </submitted-by>
    </section>

    <section>
      <!-- <submitted-by
        v-if="submitted_by"
        @close-modal="submittedBy()"
        :report_id="report_id"
        :report="report"
      ></submitted-by> -->
      <lab-test-modal
        v-if="lab_test_modal"
        @close-modal="labTest()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></lab-test-modal>
    </section>

    <section>
      <animal-bite-modal
        v-if="animal_bite_modal"
        @close-modal="animalBite()"
        :report_id="report_id"
        :report="report"
        :index="index"
      ></animal-bite-modal>
    </section>

    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import ArrowbackIcon from '../Icons/arrowbackIcon.vue'
import WarningIcon from '../Icons/warningIcon.vue'
import DiagnosisModal from './MedicalReport/DiagnosisModal.vue'
import LabReportTab from './Tabs/LabReportTab.vue'
import ChiefCompliantModal from './MedicalReport/chiefCompliantModal.vue'
import DischargeModal from './MedicalReport/DischargeModal.vue'
import LabTestModal from './LabReport/LabTestModal.vue'
import MedicalHistoryModal from './MedicalReport/MedicalHistoryModal.vue'
import PhysicalExamModal from './MedicalReport/PhysicalExamModal.vue'
import ReferredModal from './MedicalReport/ReferredModal.vue'
// import SubmittedBy from './LabReport/SubmittedBy.vue'
import MedicalReportTab from './Tabs/MedicalReportTab.vue'
import OthersReportTab from './Tabs/OthersReportTab.vue'
import AnimalBiteModal from './OthersReport/AnimalBiteModal.vue'
import ReportNav from './ReportNav.vue'
import ReportPetProfile from './Pet/ReportPetProfile.vue'
import { mapState } from 'vuex'
import LoadingModel from '../Utilities/loadingModel.vue'
import SubmittedBy from './MedicalReport/SubmittedBy.vue'
import DeleteReport from './DeleteReport.vue'
export default {
  components: {
    WarningIcon,
    ArrowbackIcon,
    MedicalReportTab,
    LabReportTab,
    PhysicalExamModal,
    ReferredModal,
    ChiefCompliantModal,
    MedicalHistoryModal,
    DiagnosisModal,
    LabTestModal,
    DischargeModal,
    SubmittedBy,
    OthersReportTab,
    AnimalBiteModal,
    ReportNav,
    ReportPetProfile,
    LoadingModel,
    DeleteReport
    // SubmittedBy
  },

  props: {
    pet_id: {
      type: String
    },
    report_id: {
      type: String
    },
    index: {
      type: [Number, String]
    }
  },

  data() {
    return {
      client_id: null,
      medical: true,
      laboratory: false,
      others: false,
      show_physical_modal: false,
      referred_modal: false,
      chief_compliant_modal: false,
      medical_history_modal: false,
      diagnosis_report: false,
      lab_test_modal: false,
      discharge_modal: false,
      submitted_by: false,
      animal_bite_modal: false
    }
  },

  computed: {
    ...mapState('reports', ['pet', 'loading']),
    ...mapState('reports', ['report', 'loading'])
  },

  watch: {
    pet() {
      if (this.pet != undefined) {
        this.client_id = this.pet.client_id
      }
    }
  },

  methods: {
    backToClientProfile() {
      this.$router.push({
        name: 'Reports_Client_Profile',
        params: { client_id: this.client_id }
      })
    },

    backToReportHistory() {
      this.$router.push({
        name: 'ReportHistory',
        params: { pet_id: this.pet_id }
      })
    },

    activeBtn(val) {
      this.medical = val.medical
      this.laboratory = val.laboratory
      this.others = val.others
    },

    physicalModal() {
      this.show_physical_modal = !this.show_physical_modal
    },

    referredModal() {
      this.referred_modal = !this.referred_modal
    },

    chiefCompliant() {
      this.chief_compliant_modal = !this.chief_compliant_modal
    },

    medicalHistory() {
      this.medical_history_modal = !this.medical_history_modal
    },

    diagnosis() {
      this.diagnosis_report = !this.diagnosis_report
    },

    labTest() {
      this.lab_test_modal = !this.lab_test_modal
    },

    dischargeModal() {
      this.discharge_modal = !this.discharge_modal
    },

    submittedBy() {
      this.submitted_by = !this.submitted_by
    },

    animalBite() {
      this.animal_bite_modal = !this.animal_bite_modal
    }
  },

  mounted() {
    if (this.pet != undefined) {
      this.client_id = this.pet.client_id
    }

    this.$store.dispatch('reports/getPet', this.pet_id)
    this.$store.dispatch('reports/getReport', this.report_id)
  }
}
</script>

<style>
.content {
  position: relative;
}
.content::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(transparent, #ffff);
}
</style>
