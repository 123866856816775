<template>
  <master-layout
    :on_active="on_active"
    @create-card="createCard"
    class="relative"
  >
    <main class="mx-auto w-87 pb-11">
      <section>
        <report-body
          :pet_id="pet_id"
          :report_id="report_id"
          :index="index"
        ></report-body>
      </section>
      <section>
        <client-form
          v-if="show_modal"
          @close-card-modal="createCard()"
        ></client-form>
      </section>

      <section
        class="absolute bottom-6 left-6 xl:left-96"
        v-if="new_app_version"
        @click="updateVersion"
      >
        <div class="cursor-pointer">
          <div
            class="
              bg-primary
              animate-bounce
              shadow-md
              hover:shadow-xl
              px-4
              py-4
              text-white text-sm
              rounded-md
            "
          >
            New Version Available! Click to update
          </div>
        </div>
      </section>
    </main>
  </master-layout>
</template>

<script>
import ClientForm from '../../components/Create/ClientForm.vue'
import MasterLayout from '../../components/Layout/MasterLayout.vue'
import ReportBody from '../../components/Reports/ReportBody.vue'
export default {
  components: { ReportBody, MasterLayout, ClientForm },
  props: {
    on_active: {
      default: 'reports',
      type: String
    },
    pet_id: {
      type: String
    },
    report_id: {
      type: String
    },
    index: {
      type: [Number, String]
    }
  },
  data() {
    return {
      show_modal: false,
      show_pet_details: false,
      report_page: false
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },

    new_app_version() {
      return this.$store.state.new_app_version
    }
  },

  methods: {
    createCard() {
      this.show_modal = !this.show_modal
    }
  },

  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  },

  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style></style>
