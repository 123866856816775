<template>
  <section>
    <nav class="grid grid-flow-row sm:grid-flow-col sm:justify-between gap-y-4">
      <ul class="inline-flex">
        <li
          class="
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            mr-3
            sm:mr-2
            rounded-md
            cursor-pointer
            hover:bg-primary hover:text-white hover:shadow-md
          "
          :class="{
            'text-white bg-primary shadow-md': medical,
            'text-black bg-gray-200': !medical
          }"
          @click="medicalBtn()"
        >
          Medical
        </li>
        <li
          class="
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            mr-3
            sm:mr-2
            cursor-pointer
            rounded-md
            hover:bg-primary hover:text-white
          "
          :class="{
            'text-white bg-primary shadow-md': laboratory,
            'text-black bg-gray-200': !laboratory
          }"
          @click="laboratoryBtn()"
        >
          Laboratory
        </li>
        <li
          class="
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            cursor-pointer
            rounded-md
            hover:bg-primary hover:text-white
          "
          :class="{
            'text-white bg-primary shadow-md': others,
            'text-black bg-gray-200': !others
          }"
          @click="othersBtn()"
        >
          Others
        </li>
      </ul>
      <ul class="" v-if="index == 1">
        <button
          class="
            py-3
            px-4
            bg-primary
            shadow-xl
            rounded-md
            flex
            justify-center
            items-center
            align-middle
            text-center
            cursor-pointer
            sm:w-44
            w-full
          "
          @click="finishedReport"
        >
          <span class="text-white">Finished</span>
          <span class="ml-1">
            <question-mark :width="'w-3'" :height="'h-3'"></question-mark>
          </span>
        </button>
      </ul>
    </nav>
  </section>
</template>

<script>
import Swal from 'sweetalert2'
import QuestionMark from '../Icons/questionMark.vue'
export default {
  components: { QuestionMark },
  props: {
    report_id: String,
    pet_id: String,
    index: [Number, String]
  },
  data() {
    return {
      medical: true,
      laboratory: false,
      others: false
    }
  },
  methods: {
    medicalBtn() {
      this.neutral()
      this.medical = true
      this.emitToParent()
    },
    laboratoryBtn() {
      this.neutral()
      this.laboratory = true
      this.emitToParent()
    },
    othersBtn() {
      this.neutral()
      this.others = true
      this.emitToParent()
    },
    neutral() {
      this.medical = false
      this.laboratory = false
      this.others = false
    },
    emitToParent() {
      var active = {
        medical: this.medical,
        laboratory: this.laboratory,
        others: this.others
      }
      this.$emit('active-button', active)
    },
    finishedReport() {
      var finished = {
        id: this.report_id,
        pet_id: this.pet_id,
        finishedAt: Date.now()
      }
      Swal.fire({
        title: 'Are you sure you want to close this report?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('reports/finishedReport', finished)

          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'closing report <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'closed successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$router.push({
                name: 'Reports'
              })

              this.$emit('close-modal')
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>
