<template>
  <main>
    <section @click="deleteReport">
      <div
        class="shadow rounded-full px-4 py-1 hover:text-gray-600 cursor-pointer"
      >
        <delete-icon :width="'w-6 sm:w-9'" :height="'h-6 sm:h-9'"></delete-icon>
      </div>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import deleteIcon from '../Icons/deleteIcon.vue'
export default {
  components: { deleteIcon },
  props: {
    pet_id: {
      type: String
    },
    report_id: {
      type: String
    },
    client_id: {
      type: String
    }
  },

  methods: {
    deleteReport() {
      var value = {
        pet_id: this.pet_id,
        id: this.report_id
      }

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('reports/deleteReport', value)
          this.$router.push({
            name: 'Reports_Client_Profile',
            params: { client_id: this.client_id }
          })
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    }
  }
}
</script>

<style scoped></style>
