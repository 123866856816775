<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">Laboratory Test</div>
            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="py-20 space-y-6 lg:w-4/5 sm:mx-auto">
              <div
                class="
                  sm:flex sm:flex-wrap sm:justify-between
                  space-y-8
                  sm:space-y-3
                  md:space-y-0
                "
              >
                <div>
                  <div class="mb-3">
                    <h1 class="text-xl">Parasitology</h1>
                  </div>

                  <div
                    v-for="(control, index) in parentology"
                    :key="index"
                    class="mb-4"
                  >
                    <input-checked
                      :checked="control.checked"
                      :unchecked="control.unchecked"
                      :index="index"
                      v-on:checked-value="checkedValue"
                    ></input-checked>
                    <span class="inline-block pl-9 font-medium text-lg">{{
                      control.name
                    }}</span>
                  </div>
                </div>

                <div>
                  <div class="mb-3">
                    <h1 class="text-xl">Pathology</h1>
                  </div>

                  <div
                    v-for="(control, index) in pathology"
                    :key="index"
                    class="mb-4"
                  >
                    <input-checked
                      :checked="control.checked"
                      :unchecked="control.unchecked"
                      :index="index"
                      v-on:checked-value="checkedValue"
                    ></input-checked>

                    <span class="inline-block pl-9 font-medium text-lg">{{
                      control.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="mb-3">
                    <h1 class="text-xl">Serology</h1>
                  </div>

                  <div
                    v-for="(control, index) in serology"
                    :key="index"
                    class="mb-4"
                  >
                    <input-checked
                      :checked="control.checked"
                      :unchecked="control.unchecked"
                      :index="index"
                      v-on:checked-value="checkedValue"
                    ></input-checked>

                    <span class="inline-block pl-9 font-medium text-lg">{{
                      control.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="mb-3">
                    <h1 class="text-xl">Necropsy</h1>
                  </div>

                  <div
                    v-for="(control, index) in necropsy"
                    :key="index"
                    class="mb-4"
                  >
                    <input-checked
                      :checked="control.checked"
                      :unchecked="control.unchecked"
                      :index="index"
                      v-on:checked-value="checkedValue"
                    ></input-checked>

                    <span class="inline-block pl-9 font-medium text-lg">{{
                      control.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="mb-3">
                    <h1 class="text-xl">Radiology</h1>
                  </div>

                  <div
                    v-for="(control, index) in radiology"
                    :key="index"
                    class="mb-4"
                  >
                    <input-checked
                      :checked="control.checked"
                      :unchecked="control.unchecked"
                      :index="index"
                      v-on:checked-value="checkedValue"
                    ></input-checked>

                    <span class="inline-block pl-9 font-medium text-lg">{{
                      control.name
                    }}</span>
                  </div>
                </div>
              </div>
            </section>
            <section class="flex justify-center" v-if="index == 1">
              <button
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import CloseIcon from '../../Icons/closeIcon.vue'
import InputChecked from '../../Utilities/inputChecked.vue'
export default {
  components: { CloseIcon, InputChecked },

  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },

  data() {
    return {
      parentology: [
        {
          name: 'feaces',
          checked: false,
          unchecked: true
        },

        {
          name: 'blood',
          checked: false,
          unchecked: true
        },

        {
          name: 'others',
          checked: false,
          unchecked: true
        }
      ],

      pathology: [
        {
          name: 'hematology',
          checked: false,
          unchecked: true
        },

        {
          name: 'chemistry',
          checked: false,
          unchecked: true
        },

        {
          name: 'Cytology',
          checked: false,
          unchecked: true
        }
      ],

      serology: [
        {
          name: 'culture',
          checked: false,
          unchecked: true
        },

        {
          name: 'chemistry',
          checked: false,
          unchecked: true
        }
      ],

      necropsy: [
        {
          name: 'Parasitology',
          checked: false,
          unchecked: true
        },

        {
          name: 'culture',
          checked: false,
          unchecked: true
        }
      ],

      radiology: [
        {
          name: 'radiograph',
          checked: false,
          unchecked: true
        },

        {
          name: 'ultrasound',
          checked: false,
          unchecked: true
        }
      ]
    }
  },

  methods: {
    checkedValue(val) {
      this.controls[val.index].checked = val.checked
      this.controls[val.index].unchecked = val.unchecked
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
