<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">Medical History</div>
            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="space-y-8 md:w-9/12 lg:w-3/5 sm:mx-auto mt-14">
              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Appetite:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="appetiteUnit"
                    :unit_from_db="appetite_unit_from_db"
                  ></input-condition>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Drinking:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="drinkingUnit"
                    :unit_from_db="drinking_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Coughing:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="coughingUnit"
                    :unit_from_db="coughing_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Sneezing:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="sneezingUnit"
                    :unit_from_db="sneezing_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Attitude:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="attitudeUnit"
                    :unit_from_db="attitude_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Vomiting:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="vomitingUnit"
                    :unit_from_db="vomiting_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Defecation:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="defecationUnit"
                    :unit_from_db="defecation_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Urination:</h1>
                </div>

                <div class="col-span-2">
                  <input-condition
                    v-on:add-unit="urinationUnit"
                    :unit_from_db="urination_unit_from_db"
                  ></input-condition>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600">Medical Notes:</h1>
                </div>

                <div class="col-span-2">
                  <textarea
                    placeholder="Notes"
                    cols="30"
                    rows="5"
                    class="
                      w-full
                      border border-gray-300
                      py-2
                      px-4
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                    v-model="medical_history.medical_note"
                  ></textarea>
                </div>
              </div>
            </section>

            <section class="flex justify-center mt-14" v-if="index == 1">
              <button
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
                @click="saveDoc"
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../../Icons/closeIcon.vue'
import InputCondition from '../../Utilities/inputCondition.vue'
export default {
  components: { closeIcon, InputCondition },
  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },

  data() {
    return {
      check: false,
      date: '',
      medical_history: {
        appetite: null,
        drinking: null,
        coughing: null,
        sneezing: null,
        attitude: null,
        vomiting: null,
        defecation: null,
        urination: null,
        medical_notes: null
      },
      appetite_unit_from_db: null,
      drinking_unit_from_db: null,
      coughing_unit_from_db: null,
      sneezing_unit_from_db: null,
      attitude_unit_from_db: null,
      vomiting_unit_from_db: null,
      defecation_unit_from_db: null,
      urination_unit_from_db: null,
      report_from_db: this.report
    }
  },

  watch: {
    appetite(val) {
      if (val == '') {
        this.medical_history.appetite = null
        this.appetite_unit_from_db = null
      }
    },

    drinking(val) {
      if (val == '') {
        this.medical_history.drinking = null
        this.drinking_unit_from_db = null
      }
    },

    coughing(val) {
      if (val == '') {
        this.medical_history.coughing = null
        this.coughing_unit_from_db = null
      }
    },

    sneezing(val) {
      if (val == '') {
        this.medical_history.sneezing = null
        this.sneezing_unit_from_db = null
      }
    },

    attitude(val) {
      if (val == '') {
        this.medical_history.attitude = null
        this.attitude_unit_from_db = null
      }
    },

    vomiting(val) {
      if (val == '') {
        this.medical_history.vomiting = null
        this.vomiting_unit_from_db = null
      }
    },

    defecation(val) {
      if (val == '') {
        this.medical_history.defecation = null
        this.defecation_unit_from_db = null
      }
    },

    urination(val) {
      if (val == '') {
        this.medical_history.appetite = null
        this.urination_unit_from_db = null
      }
    },

    medical_notes(val) {
      if (val == '') {
        this.medical_history.medical_notes = null
      }
    },

    report_from_db(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.medical_history != undefined
      ) {
        if (val.medical.medical_history.appetite != undefined) {
          this.medical_history.appetite = val.medical.medical_history.appetite
          this.appetite_unit_from_db = val.medical.medical_history.appetite
        }

        if (val.medical.medical_history.drinking != undefined) {
          this.medical_history.drinking = val.medical.medical_history.drinking
          this.drinking_unit_from_db = val.medical.medical_history.drinking
        }

        if (val.medical.medical_history.coughing != undefined) {
          this.medical_history.coughing = val.medical.medical_history.coughing
          this.coughing_unit_from_db = val.medical.medical_history.coughing
        }

        if (val.medical.medical_history.sneezing != undefined) {
          this.medical_history.sneezing = val.medical.medical_history.sneezing
          this.sneezing_unit_from_db = val.medical.medical_history.sneezing
        }

        if (val.medical.medical_history.attitude != undefined) {
          this.medical_history.attitude = val.medical.medical_history.attitude
          this.attitude_unit_from_db = val.medical.medical_history.attitude
        }

        if (val.medical.medical_history.vomiting != undefined) {
          this.medical_history.vomiting = val.medical.medical_history.vomiting
          this.vomiting_unit_from_db = val.medical.medical_history.vomiting
        }

        if (val.medical.medical_history.defecation != undefined) {
          this.medical_history.defecation =
            val.medical.medical_history.defecation
          this.defecation_unit_from_db = val.medical.medical_history.defecation
        }

        if (val.medical.medical_history.urination != undefined) {
          this.medical_history.urination = val.medical.medical_history.urination
          this.urination_unit_from_db = val.medical.medical_history.urination
        }

        if (val.medical.medical_history.medical_notes != undefined) {
          this.medical_history.medical_notes =
            val.medical.medical_history.medical_notes
        }
      }
    }
  },

  methods: {
    appetiteUnit(val) {
      if (val != undefined) {
        this.medical_history.appetite = val
      }
    },

    drinkingUnit(val) {
      if (val != undefined) {
        this.medical_history.drinking = val
      }
    },

    coughingUnit(val) {
      if (val != undefined) {
        this.medical_history.coughing = val
      }
    },

    sneezingUnit(val) {
      if (val != undefined) {
        this.medical_history.sneezing = val
      }
    },

    attitudeUnit(val) {
      if (val != undefined) {
        this.medical_history.attitude = val
      }
    },

    vomitingUnit(val) {
      if (val != undefined) {
        this.medical_history.vomiting = val
      }
    },

    defecationUnit(val) {
      if (val != undefined) {
        this.medical_history.defecation = val
      }
    },

    urinationUnit(val) {
      if (val != undefined) {
        this.medical_history.urination = val
      }
    },

    saveDoc() {
      var value = {
        id: this.report_id,
        appetite: this.medical_history.appetite,
        drinking: this.medical_history.drinking,
        coughing: this.medical_history.coughing,
        sneezing: this.medical_history.sneezing,
        attitude: this.medical_history.attitude,
        vomiting: this.medical_history.vomiting,
        defecation: this.medical_history.defecation,
        urination: this.medical_history.urination,
        medical_notes: this.medical_history.medical_notes
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('medical_medical_history/update', value)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report_from_db != undefined &&
      this.report_from_db.medical != undefined &&
      this.report_from_db.medical.medical_history != undefined
    ) {
      if (this.report_from_db.medical.medical_history.appetite != undefined) {
        this.medical_history.appetite =
          this.report_from_db.medical.medical_history.appetite
        this.appetite_unit_from_db =
          this.report_from_db.medical.medical_history.appetite
      }

      if (this.report_from_db.medical.medical_history.drinking != undefined) {
        this.medical_history.drinking =
          this.report_from_db.medical.medical_history.drinking
        this.drinking_unit_from_db =
          this.report_from_db.medical.medical_history.drinking
      }

      if (this.report_from_db.medical.medical_history.coughing != undefined) {
        this.medical_history.coughing =
          this.report_from_db.medical.medical_history.coughing
        this.coughing_unit_from_db =
          this.report_from_db.medical.medical_history.coughing
      }

      if (this.report_from_db.medical.medical_history.sneezing != undefined) {
        this.medical_history.sneezing =
          this.report_from_db.medical.medical_history.sneezing
        this.sneezing_unit_from_db =
          this.report_from_db.medical.medical_history.sneezing
      }

      if (this.report_from_db.medical.medical_history.attitude != undefined) {
        this.medical_history.attitude =
          this.report_from_db.medical.medical_history.attitude
        this.attitude_unit_from_db =
          this.report_from_db.medical.medical_history.attitude
      }

      if (this.report_from_db.medical.medical_history.vomiting != undefined) {
        this.medical_history.vomiting =
          this.report_from_db.medical.medical_history.vomiting
        this.vomiting_unit_from_db =
          this.report_from_db.medical.medical_history.vomiting
      }

      if (this.report_from_db.medical.medical_history.defecation != undefined) {
        this.medical_history.defecation =
          this.report_from_db.medical.medical_history.defecation
        this.defecation_unit_from_db =
          this.report_from_db.medical.medical_history.defecation
      }

      if (this.report_from_db.medical.medical_history.urination != undefined) {
        this.medical_history.urination =
          this.report_from_db.medical.medical_history.urination
        this.urination_unit_from_db =
          this.report_from_db.medical.medical_history.urination
      }

      if (
        this.report_from_db.medical.medical_history.medical_notes != undefined
      ) {
        this.medical_history.medical_notes =
          this.report_from_db.medical.medical_history.medical_notes
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
