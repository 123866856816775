<template>
  <section class="bg-white px-4 sm:px-8 pt-6 pb-10 rounded shadow mt-6">
    <div class="float-right">
      <h1 class="text-primary text-2xl">12,365 records</h1>
    </div>
    <div class="pt-16 sm:px-10 space-y-4">
      <a
        class="
          flex
          bg-gray-200
          items-center
          justify-center
          rounded
          space-x-6
          py-2
          hover:bg-primary hover:text-white
          transition
        "
        href="javascript:;"
      >
        <div class="w-56 flex justify-between" v-if="index == 1">
          <p>
            <span v-if="lab_test_result_is_empty">Add</span>
            <span v-if="lab_test_result_is_not_empty">View</span> Laboratory
            Test Result
          </p>
          <lock-icon :width="'w-5'" :height="'h-5'"></lock-icon>
          <!-- <p>
            <span v-if="lab_test_result_is_empty">Add</span>
            <span v-if="lab_test_result_is_not_empty">View</span> Laboratory
            Test Result
          </p>
          <add-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="lab_test_result_is_empty"
          ></add-icon>
          <view-icon
            :width="'w-5'"
            :height="'h-5'"
            v-if="lab_test_result_is_not_empty"
          ></view-icon> -->
        </div>
        <div class="w-56 flex justify-between" v-if="index == 0">
          <div><span>View</span> Laboratory Test Result</div>
          <view-icon :width="'w-5'" :height="'h-5'"></view-icon>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LockIcon from '../../Icons/lockIcon.vue'
// import addIcon from '../../Icons/addIcon.vue'
import ViewIcon from '../../Icons/viewIcon.vue'
export default {
  components: { /* addIcon, */ ViewIcon, LockIcon },
  props: {
    report_id: String,
    index: [Number, String]
  },
  data() {
    return {
      lab_test_result_is_empty: false,
      lab_test_result_is_not_empty: false
    }
  },
  computed: {
    ...mapState('reports', ['report'])
  },
  watch: {
    report(val) {
      if (val != undefined && val.laboratory != undefined) {
        if (val.laboratory.lab_test_result != undefined) {
          this.lab_test_result_is_not_empty = true
          this.lab_test_result_is_empty = false
        } else {
          this.lab_test_result_is_empty = true
          this.lab_test_result_is_not_empty = false
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('reports/getReport', this.report_id)

    if (this.report != undefined && this.report.laboratory != undefined) {
      if (this.report.laboratory.lab_test_result != undefined) {
        this.lab_test_result_is_not_empty = true
        this.lab_test_result_is_empty = false
      } else {
        this.lab_test_result_is_empty = true
        this.lab_test_result_is_not_empty = false
      }
    }
  }
}
</script>

<style></style>

note: commented section are functional event but are not available for use at
the time it was commented. note: @click="$emit('lab-test-modal')" add this code
line back too the link tag
