<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">
              Physical Examination
            </div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="sm:w-4/5 mx-auto">
              <section class="space-y-6 md:w-9/12 lg:w-4/5 sm:mx-auto mt-14">
                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">General Appearance:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="generalApp"
                      :unit_from_db="general_app_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Integumentary:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Integumentary"
                      :unit_from_db="integumentary_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Lymph Nodes:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="lymphNodes"
                      :unit_from_db="lymph_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Nervous:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Nervous"
                      :unit_from_db="nervous_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Musculo-skeletal:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="musculoSkeletal"
                      :unit_from_db="musculo_skeletal_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Eye:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Eye"
                      :unit_from_db="eye_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Circulatory:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Circulatory"
                      :unit_from_db="circulatory_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Respirtory:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Respirtory"
                      :unit_from_db="respirtory_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Disgestive:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="Disgestive"
                      :unit_from_db="disgestive_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Genito-Urinary:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="genitoUrinary"
                      :unit_from_db="genito_urinary_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                  <div class="col-span-1">
                    <h1 class="text-lg">Mucous Membranes:</h1>
                  </div>

                  <div class="col-span-2">
                    <input-condition
                      v-on:add-unit="mucousMembranes"
                      :unit_from_db="mucous_membranes_unit_from_db"
                    ></input-condition>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                  <div class="col-span-1">
                    <h1 class="text-lg">Temperature:</h1>
                  </div>

                  <div class="col-span-1">
                    <input-temperature
                      v-on:add-unit="Temperature"
                      :unit_from_db="temperature_rate_from_db"
                    ></input-temperature>
                  </div>

                  <div class="col-span-1">
                    <input-text
                      type="number"
                      v-model="physical_exam.temperature.temperature_unit"
                    ></input-text>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                  <div class="col-span-1">
                    <h1 class="text-lg">Pulse Rate:</h1>
                  </div>

                  <div class="col-span-1">
                    <input-rate
                      v-on:add-unit="pulseRate"
                      :unit_from_db="pulse_rate_from_db"
                    ></input-rate>
                  </div>

                  <div class="col-span-1">
                    <input-text
                      type="number"
                      v-model="physical_exam.pulse.pulse_unit"
                    ></input-text>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                  <div class="col-span-1">
                    <h1 class="text-lg">Respirtory Rate:</h1>
                  </div>

                  <div class="col-span-1">
                    <input-rate
                      v-on:add-unit="respirtoryRate"
                      :unit_from_db="respirtory_measure_from_db"
                    ></input-rate>
                  </div>

                  <div class="col-span-1">
                    <input-text
                      type="number"
                      v-model="physical_exam.respirtory_rate.respirtory_unit"
                    ></input-text>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                  <div class="col-span-1">
                    <h1 class="text-lg">Body Measure:</h1>
                  </div>

                  <div class="col-span-1">
                    <input-body-measure
                      v-on:add-unit="bodyMeasure"
                      :unit_from_db="body_measure_from_db"
                    ></input-body-measure>
                  </div>

                  <div class="col-span-1">
                    <input-text
                      v-model="physical_exam.body.body_unit"
                    ></input-text>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                  <div class="col-span-1">
                    <h1 class="text-lg">Medical Notes:</h1>
                  </div>

                  <div class="col-span-2">
                    <textarea
                      v-model="physical_exam.medical_note"
                      cols="30"
                      rows="5"
                      class="
                        w-full
                        border border-gray-300
                        py-2
                        px-4
                        outline-none
                        rounded-md
                        text-lg
                        focus:border-primary
                      "
                    ></textarea>
                  </div>
                </div>
              </section>

              <section class="flex justify-center py-10" v-if="index == 1">
                <button
                  @click="saveDoc"
                  class="
                    bg-primary
                    text-white
                    px-14
                    py-2
                    text-center
                    rounded
                    shadow
                    hover:shadow-xl
                  "
                >
                  Save
                </button>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import CloseIcon from '../../Icons/closeIcon.vue'
import InputBodyMeasure from '../../Utilities/inputBodyMeasure.vue'
import InputCondition from '../../Utilities/inputCondition.vue'
import InputRate from '../../Utilities/inputRate.vue'
import InputTemperature from '../../Utilities/inputTemperature.vue'
import InputText from '../../Utilities/InputText.vue'
export default {
  components: {
    CloseIcon,
    InputCondition,
    InputTemperature,
    InputText,
    InputRate,
    InputBodyMeasure
  },
  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      report_from_db: this.report,
      physical_exam: {
        general_appearance: null,
        integumentary: null,
        lymph_nodes: null,
        nervous: null,
        musculo_skeletal: null,
        eye: null,
        circulatory: null,
        respirtory: null,
        disgestive: null,
        genito_urinary: null,
        mucous_membranes: null,
        temperature: {
          temperature_measure: null,
          temperature_unit: null
        },
        pulse: {
          pulse_rate: null,
          pulse_unit: null
        },
        respirtory_rate: {
          respirtory_measure: null,
          respirtory_unit: null
        },
        body: {
          body_measure: null,
          body_unit: null
        },
        medical_note: null
      },
      general_app_unit_from_db: null,
      integumentary_unit_from_db: null,
      lymph_unit_from_db: null,
      nervous_unit_from_db: null,
      musculo_skeletal_unit_from_db: null,
      eye_unit_from_db: null,
      circulatory_unit_from_db: null,
      respirtory_unit_from_db: null,
      disgestive_unit_from_db: null,
      genito_urinary_unit_from_db: null,
      mucous_membranes_unit_from_db: null,
      temperature_rate_from_db: null,
      pulse_rate_from_db: null,
      respirtory_measure_from_db: null,
      body_measure_from_db: null
    }
  },

  watch: {
    general_appearance(val) {
      if (val == '') {
        this.physical_exam.general_appearance = null
        this.genito_urinary_unit_from_db = null
      }
    },

    integumentary(val) {
      if (val == '') {
        this.physical_exam.integumentary = null
        this.integumentary_unit_from_db = null
      }
    },

    lymph_nodes(val) {
      if (val == '') {
        this.physical_exam.lymph_nodes = null
        this.lymph_unit_from_db = null
      }
    },

    nervous(val) {
      if (val == '') {
        this.physical_exam.nervous = null
        this.nervous_unit_from_db = null
      }
    },

    musculo_skeletal(val) {
      if (val == '') {
        this.physical_exam.musculo_skeletal = null
        this.musculo_skeletal_unit_from_db = null
      }
    },

    eye(val) {
      if (val == '') {
        this.physical_exam.eye = null
        this.eye_unit_from_db = null
      }
    },

    circulatory(val) {
      if (val == '') {
        this.physical_exam.circulatory = null
        this.circulatory_unit_from_db = null
      }
    },

    respirtory(val) {
      if (val == '') {
        this.physical_exam.respirtory = null
        this.respirtory_unit_from_db = null
      }
    },

    disgestive(val) {
      if (val == '') {
        this.physical_exam.disgestive = null
        this.disgestive_unit_from_db = null
      }
    },

    genito_urinary(val) {
      if (val == '') {
        this.physical_exam.genito_urinary = null
        this.genito_urinary_unit_from_db = null
      }
    },

    mucous_membranes(val) {
      if (val == '') {
        this.physical_exam.mucous_membranes = null
        this.mucous_membranes_unit_from_db = null
      }
    },

    temperature_measure(val) {
      if (val == '') {
        this.physical_exam.temperature.temperature_measure = null
        this.temperature_rate_from_db = null
      }
    },

    temperature_unit(val) {
      if (val == '') {
        this.physical_exam.temperature.temperature_unit = null
      }
    },

    pulse_rate(val) {
      if (val == '') {
        this.physical_exam.pulse.pulse_rate = null
        this.pulse_rate_from_db = null
      }
    },

    pulse_unit(val) {
      if (val == '') {
        this.physical_exam.pulse.pulse_unit = null
      }
    },

    respirtory_measure(val) {
      if (val == '') {
        this.physical_exam.respirtory_rate.respirtory_measure = null
        this.respirtory_measure_from_db = null
      }
    },

    respirtory_unit(val) {
      if (val == '') {
        this.physical_exam.respirtory_rate.respirtory_unit = null
      }
    },

    body_measure(val) {
      if (val == '') {
        this.physical_exam.body.body_measure = null
        this.body_measure_from_db = null
      }
    },

    body_unit(val) {
      if (val == '') {
        this.physical_exam.body.body_unit = null
      }
    },

    medical_note(val) {
      if (val == '') {
        this.physical_exam.medical_note = null
      }
    },

    report_from_db(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.physical_examination != undefined
      ) {
        if (val.medical.physical_examination.general_appearance != undefined) {
          this.physical_exam.general_appearance =
            val.medical.physical_examination.general_appearance
          this.general_app_unit_from_db =
            val.medical.physical_examination.general_appearance
        }

        if (val.medical.physical_examination.integumentary != undefined) {
          this.physical_exam.integumentary =
            val.medical.physical_examination.integumentary
          this.integumentary_unit_from_db =
            val.medical.physical_examination.integumentary
        }

        if (val.medical.physical_examination.lymph_nodes != undefined) {
          this.physical_exam.lymph_nodes =
            val.medical.physical_examination.lymph_nodes
          this.lymph_unit_from_db = val.medical.physical_examination.lymph_nodes
        }

        if (val.medical.physical_examination.nervous != undefined) {
          this.physical_exam.nervous = val.medical.physical_examination.nervous
          this.nervous_unit_from_db = val.medical.physical_examination.nervous
        }

        if (val.medical.physical_examination.musculo_skeletal != undefined) {
          this.physical_exam.musculo_skeletal =
            val.medical.physical_examination.musculo_skeletal
          this.musculo_skeletal_unit_from_db =
            val.medical.physical_examination.musculo_skeletal
        }

        if (val.medical.physical_examination.eye != undefined) {
          this.physical_exam.eye = val.medical.physical_examination.eye
          this.eye_unit_from_db = val.medical.physical_examination.eye
        }

        if (val.medical.physical_examination.circulatory != undefined) {
          this.physical_exam.circulatory =
            val.medical.physical_examination.circulatory
          this.circulatory_unit_from_db =
            val.medical.physical_examination.circulatory
        }

        if (val.medical.physical_examination.respirtory != undefined) {
          this.physical_exam.respirtory =
            val.medical.physical_examination.respirtory
          this.respirtory_unit_from_db =
            val.medical.physical_examination.respirtory
        }

        if (val.medical.physical_examination.disgestive != undefined) {
          this.physical_exam.disgestive =
            val.medical.physical_examination.disgestive
          this.disgestive_unit_from_db =
            val.medical.physical_examination.disgestive
        }

        if (val.medical.physical_examination.genito_urinary != undefined) {
          this.physical_exam.genito_urinary =
            val.medical.physical_examination.genito_urinary
          this.genito_urinary_unit_from_db =
            val.medical.physical_examination.genito_urinary
        }

        if (val.medical.physical_examination.mucous_membranes != undefined) {
          this.physical_exam.mucous_membranes =
            val.medical.physical_examination.mucous_membranes
          this.mucous_membranes_unit_from_db =
            val.medical.physical_examination.mucous_membranes
        }

        if (
          val.medical.physical_examination.temperature.temperature_measure !=
          undefined
        ) {
          this.physical_exam.temperature.temperature_measure =
            val.medical.physical_examination.temperature_measure
          this.temperature_rate_from_db =
            val.medical.physical_examination.temperature_measure
        }

        if (
          val.medical.physical_examination.temperature.temperature_unit !=
          undefined
        ) {
          this.physical_exam.temperature.temperature_unit =
            val.medical.physical_examination.temperature.temperature_unit
        }

        if (val.medical.physical_examination.pulse.pulse_rate != undefined) {
          this.physical_exam.pulse.pulse_rate =
            val.medical.physical_examination.pulse.pulse_rate
          this.pulse_rate_from_db =
            val.medical.physical_examination.pulse.pulse_rate
        }

        if (val.medical.physical_examination.pulse.pulse_unit != undefined) {
          this.physical_exam.pulse.pulse_unit =
            val.medical.physical_examination.pulse.pulse_unit
        }

        if (
          val.medical.physical_examination.respirtory_rate.respirtory_measure !=
          undefined
        ) {
          this.physical_exam.respirtory_rate.respirtory_measure =
            val.medical.physical_examination.respirtory_rate.respirtory_measure
          this.respirtory_measure_from_db =
            val.medical.physical_examination.respirtory_rate.respirtory_measure
        }

        if (val.medical.physical_examination.body.body_measure != undefined) {
          this.physical_exam.body.body_measure =
            val.medical.physical_examination.body.body_measure
          this.body_measure_from_db =
            val.medical.physical_examination.body.body_measure
        }

        if (val.medical.physical_examination.body.body_unit != undefined) {
          this.physical_exam.body.body_unit =
            val.medical.physical_examination.body.body_unit
        }

        if (val.medical.physical_examination.medical_note != undefined) {
          this.physical_exam.medical_note =
            val.medical.physical_examination.medical_note
        }
      }
    }
  },
  methods: {
    generalApp(val) {
      if (val != undefined) {
        this.physical_exam.general_appearance = val
      }
    },

    Integumentary(val) {
      if (val != undefined) {
        this.physical_exam.integumentary = val
      }
    },

    lymphNodes(val) {
      if (val != undefined) {
        this.physical_exam.lymph_nodes = val
      }
    },

    Nervous(val) {
      if (val != undefined) {
        this.physical_exam.nervous = val
      }
    },

    musculoSkeletal(val) {
      if (val != undefined) {
        this.physical_exam.musculo_skeletal = val
      }
    },
    Eye(val) {
      if (val != undefined) {
        this.physical_exam.eye = val
      }
    },

    Circulatory(val) {
      if (val != undefined) {
        this.physical_exam.circulatory = val
      }
    },

    Respirtory(val) {
      if (val != undefined) {
        this.physical_exam.respirtory = val
      }
    },

    Disgestive(val) {
      if (val != undefined) {
        this.physical_exam.disgestive = val
      }
    },

    genitoUrinary(val) {
      if (val != undefined) {
        this.physical_exam.genito_urinary = val
      }
    },

    mucousMembranes(val) {
      if (val != undefined) {
        this.physical_exam.mucous_membranes = val
      }
    },

    Temperature(val) {
      if (val != undefined) {
        this.physical_exam.temperature.temperature_measure = val
      }
    },

    pulseRate(val) {
      if (val != undefined) {
        this.physical_exam.pulse.pulse_rate = val
      }
    },

    respirtoryRate(val) {
      if (val != undefined) {
        this.physical_exam.respirtory_rate.respirtory_measure = val
      }
    },

    bodyMeasure(val) {
      if (val != undefined) {
        this.physical_exam.body.body_measure = val
      }
    },

    saveDoc() {
      var physical_exam = {
        id: this.report_id,
        general_appearance: this.physical_exam.general_appearance,
        integumentary: this.physical_exam.integumentary,
        lymph_nodes: this.physical_exam.lymph_nodes,
        nervous: this.physical_exam.nervous,
        musculo_skeletal: this.physical_exam.mucous_membranes,
        eye: this.physical_exam.eye,
        circulatory: this.physical_exam.circulatory,
        respirtory: this.physical_exam.respirtory,
        disgestive: this.physical_exam.disgestive,
        genito_urinary: this.physical_exam.genito_urinary,
        mucous_membranes: this.physical_exam.mucous_membranes,
        temperature: {
          temperature_measure:
            this.physical_exam.temperature.temperature_measure,
          temperature_unit: this.physical_exam.temperature.temperature_unit
        },
        pulse: {
          pulse_rate: this.physical_exam.pulse.pulse_rate,
          pulse_unit: this.physical_exam.pulse.pulse_unit
        },
        respirtory_rate: {
          respirtory_measure:
            this.physical_exam.respirtory_rate.respirtory_measure,
          respirtory_unit: this.physical_exam.respirtory_rate.respirtory_unit
        },
        body: {
          body_measure: this.physical_exam.body.body_measure,
          body_unit: this.physical_exam.body.body_unit
        },
        medical_note: this.physical_exam.medical_note
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(
            'medical_physical_examination/update',
            physical_exam
          )
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },
  mounted() {
    if (
      this.report_from_db != undefined &&
      this.report_from_db.medical != undefined &&
      this.report_from_db.medical.physical_examination != undefined
    ) {
      if (
        this.report_from_db.medical.physical_examination.general_appearance !=
        undefined
      ) {
        this.physical_exam.general_appearance =
          this.report_from_db.medical.physical_examination.general_appearance
        this.general_app_unit_from_db =
          this.report_from_db.medical.physical_examination.general_appearance
      }

      if (
        this.report_from_db.medical.physical_examination.integumentary !=
        undefined
      ) {
        this.physical_exam.integumentary =
          this.report_from_db.medical.physical_examination.integumentary
        this.integumentary_unit_from_db =
          this.report_from_db.medical.physical_examination.integumentary
      }

      if (
        this.report_from_db.medical.physical_examination.lymph_nodes !=
        undefined
      ) {
        this.physical_exam.lymph_nodes =
          this.report_from_db.medical.physical_examination.lymph_nodes
        this.lymph_unit_from_db =
          this.report_from_db.medical.physical_examination.lymph_nodes
      }

      if (
        this.report_from_db.medical.physical_examination.nervous != undefined
      ) {
        this.physical_exam.nervous =
          this.report_from_db.medical.physical_examination.nervous
        this.nervous_unit_from_db =
          this.report_from_db.medical.physical_examination.nervous
      }

      if (
        this.report_from_db.medical.physical_examination.musculo_skeletal !=
        undefined
      ) {
        this.physical_exam.musculo_skeletal =
          this.report_from_db.medical.physical_examination.musculo_skeletal
        this.musculo_skeletal_unit_from_db =
          this.report_from_db.medical.physical_examination.musculo_skeletal
      }

      if (this.report_from_db.medical.physical_examination.eye != undefined) {
        this.physical_exam.eye =
          this.report_from_db.medical.physical_examination.eye
        this.eye_unit_from_db =
          this.report_from_db.medical.physical_examination.eye
      }

      if (
        this.report_from_db.medical.physical_examination.circulatory !=
        undefined
      ) {
        this.physical_exam.circulatory =
          this.report_from_db.medical.physical_examination.circulatory
        this.circulatory_unit_from_db =
          this.report_from_db.medical.physical_examination.circulatory
      }

      if (
        this.report_from_db.medical.physical_examination.respirtory != undefined
      ) {
        this.physical_exam.respirtory =
          this.report_from_db.medical.physical_examination.respirtory
        this.respirtory_unit_from_db =
          this.report_from_db.medical.physical_examination.respirtory
      }

      if (
        this.report_from_db.medical.physical_examination.disgestive != undefined
      ) {
        this.physical_exam.disgestive =
          this.report_from_db.medical.physical_examination.disgestive
        this.disgestive_unit_from_db =
          this.report_from_db.medical.physical_examination.disgestive
      }

      if (
        this.report_from_db.medical.physical_examination.genito_urinary !=
        undefined
      ) {
        this.physical_exam.genito_urinary =
          this.report_from_db.medical.physical_examination.genito_urinary
        this.genito_urinary_unit_from_db =
          this.report_from_db.medical.physical_examination.genito_urinary
      }

      if (
        this.report_from_db.medical.physical_examination.mucous_membranes !=
        undefined
      ) {
        this.physical_exam.mucous_membranes =
          this.report_from_db.medical.physical_examination.mucous_membranes
        this.mucous_membranes_unit_from_db =
          this.report_from_db.medical.physical_examination.mucous_membranes
      }

      if (
        this.report_from_db.medical.physical_examination.temperature
          .temperature_measure != undefined
      ) {
        this.physical_exam.temperature.temperature_measure =
          this.report_from_db.medical.physical_examination.temperature.temperature_measure
        this.temperature_rate_from_db =
          this.report_from_db.medical.physical_examination.temperature.temperature_measure
      }

      if (
        this.report_from_db.medical.physical_examination.temperature
          .temperature_unit != undefined
      ) {
        this.physical_exam.temperature.temperature_unit =
          this.report_from_db.medical.physical_examination.temperature.temperature_unit
      }

      if (
        this.report_from_db.medical.physical_examination.pulse.pulse_rate !=
        undefined
      ) {
        this.physical_exam.pulse.pulse_rate =
          this.report_from_db.medical.physical_examination.pulse.pulse_rate
        this.pulse_rate_from_db =
          this.report_from_db.medical.physical_examination.pulse.pulse_rate
      }

      if (
        this.report_from_db.medical.physical_examination.pulse.pulse_unit !=
        undefined
      ) {
        this.physical_exam.pulse.pulse_unit =
          this.report_from_db.medical.physical_examination.pulse.pulse_unit
      }

      if (
        this.report_from_db.medical.physical_examination.respirtory_rate
          .respirtory_measure != undefined
      ) {
        this.physical_exam.respirtory_rate.respirtory_measure =
          this.report_from_db.medical.physical_examination.respirtory_rate.respirtory_measure
        this.respirtory_measure_from_db =
          this.report_from_db.medical.physical_examination.respirtory_rate.respirtory_measure
      }

      if (
        this.report_from_db.medical.physical_examination.respirtory_rate
          .respirtory_unit != undefined
      ) {
        this.physical_exam.respirtory_rate.respirtory_unit =
          this.report_from_db.medical.physical_examination.respirtory_rate.respirtory_unit
      }

      if (
        this.report_from_db.medical.physical_examination.body.body_measure !=
        undefined
      ) {
        this.physical_exam.body.body_measure =
          this.report_from_db.medical.physical_examination.body.body_measure
        this.body_measure_from_db =
          this.report_from_db.medical.physical_examination.body.body_measure
      }

      if (
        this.report_from_db.medical.physical_examination.body.body_unit !=
        undefined
      ) {
        this.physical_exam.body.body_unit =
          this.report_from_db.medical.physical_examination.body.body_unit
        this.body_measure_from_db =
          this.report_from_db.medical.physical_examination.body.body_measure
      }

      if (
        this.report_from_db.medical.physical_examination.body.body_unit !=
        undefined
      ) {
        this.physical_exam.body.body_unit =
          this.report_from_db.medical.physical_examination.body.body_unit
      }

      if (
        this.report_from_db.medical.physical_examination.medical_note !=
        undefined
      ) {
        this.physical_exam.medical_note =
          this.report_from_db.medical.physical_examination.medical_note
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
