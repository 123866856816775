var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('nav',{staticClass:"grid grid-flow-row sm:grid-flow-col sm:justify-between gap-y-4"},[_c('ul',{staticClass:"inline-flex"},[_c('li',{staticClass:"\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          mr-3\n          sm:mr-2\n          rounded-md\n          cursor-pointer\n          hover:bg-primary hover:text-white hover:shadow-md\n        ",class:{
          'text-white bg-primary shadow-md': _vm.medical,
          'text-black bg-gray-200': !_vm.medical
        },on:{"click":function($event){return _vm.medicalBtn()}}},[_vm._v(" Medical ")]),_c('li',{staticClass:"\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          mr-3\n          sm:mr-2\n          cursor-pointer\n          rounded-md\n          hover:bg-primary hover:text-white\n        ",class:{
          'text-white bg-primary shadow-md': _vm.laboratory,
          'text-black bg-gray-200': !_vm.laboratory
        },on:{"click":function($event){return _vm.laboratoryBtn()}}},[_vm._v(" Laboratory ")]),_c('li',{staticClass:"\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          cursor-pointer\n          rounded-md\n          hover:bg-primary hover:text-white\n        ",class:{
          'text-white bg-primary shadow-md': _vm.others,
          'text-black bg-gray-200': !_vm.others
        },on:{"click":function($event){return _vm.othersBtn()}}},[_vm._v(" Others ")])]),(_vm.index == 1)?_c('ul',{},[_c('button',{staticClass:"\n          py-3\n          px-4\n          bg-primary\n          shadow-xl\n          rounded-md\n          flex\n          justify-center\n          items-center\n          align-middle\n          text-center\n          cursor-pointer\n          sm:w-44\n          w-full\n        ",on:{"click":_vm.finishedReport}},[_c('span',{staticClass:"text-white"},[_vm._v("Finished")]),_c('span',{staticClass:"ml-1"},[_c('question-mark',{attrs:{"width":'w-3',"height":'h-3'}})],1)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }