<template>
  <div>
    <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
      <div class="col-span-1">
        <h1 class="text-lg">State:</h1>
      </div>
      <div class="pb-6 col-span-3">
        <div @click="show_state = !show_state">
          <input-text
            type="text"
            placeholder="State"
            readonly
            v-model="state"
          ></input-text>
          <div class="relative">
            <a href="javascript:;">
              <selectarrow-icon
                class="absolute right-0 -mt-8 mr-2"
                :width="'w-4'"
                :height="'h-4'"
                :color="'text-primary'"
              ></selectarrow-icon>
            </a>
          </div>
        </div>
        <transition name="localform">
          <div class="relative" v-if="show_state">
            <div
              class="
                bg-white
                absolute
                z-30
                rounded
                mt-1
                w-full
                border-t
                shadow-md
              "
            >
              <input
                type="text"
                class="
                  focus:outline-none
                  px-3
                  py-2
                  bg-white
                  shadow
                  rounded-t
                  mb-2
                  w-full
                "
                placeholder="Search State"
                v-model="state_search"
              />
              <div class="overflow-y-scroll max-h-44">
                <p
                  class="px-3 py-2 hover:bg-gray-300"
                  :class="{
                    'rounded-t': index == 0,
                    'rounded-b': index + 1 == new_states.length
                  }"
                  @click="selectState(index)"
                  v-for="(state, index) in new_states"
                  :key="index"
                >
                  {{ state.name }}
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-center">
      <div class="col-span-1">
        <h1 class="text-lg">LGA:</h1>
      </div>
      <div class="col-span-3">
        <div @click="show_local_govt = !show_local_govt">
          <input-text
            type="text"
            placeholder="Local Govt"
            readonly
            v-model="local_govt"
          ></input-text>
          <div class="relative">
            <a href="javascript:;">
              <selectarrow-icon
                class="absolute z-20 right-0 -mt-8 mr-2"
                :width="'w-4'"
                :height="'h-4'"
                :color="'text-primary'"
              ></selectarrow-icon>
            </a>
          </div>
        </div>
        <transition name="localform">
          <div class="relative" v-if="show_local_govt && state != ''">
            <div
              class="
                bg-white
                absolute
                z-30
                rounded
                mt-1
                w-full
                border-t
                shadow-md
              "
            >
              <input
                type="text"
                class="
                  focus:outline-none
                  px-3
                  py-2
                  bg-white
                  shadow
                  rounded-t
                  mb-2
                  w-full
                "
                placeholder="Search State"
                v-model="local_search"
              />
              <div class="overflow-y-scroll max-h-44">
                <p
                  class="px-3 py-2 hover:bg-gray-300"
                  :class="{
                    'rounded-t': index == 0,
                    'rounded-b': index + 1 == new_local_govts.length
                  }"
                  @click="selectLocalGovt(index)"
                  v-for="(local, index) in new_local_govts"
                  :key="index"
                >
                  {{ local.name }}
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SelectarrowIcon from '../Icons/selectarrowIcon.vue'
import InputText from './InputText.vue'
export default {
  props: {
    state_from_db: String,
    local_govt_from_db: String
  },
  components: { InputText, SelectarrowIcon },
  data() {
    return {
      states: [
        {
          name: 'Abia State',
          id: 1,
          locals: [
            { name: 'Aba South', id: 1 },
            { name: 'Aba North', id: 2 },
            { name: 'Arochukwu', id: 3 },
            { name: 'Bende', id: 4 },
            { name: 'Ikwuano', id: 5 },
            { name: 'Isiala Ngwa North', id: 6 },
            { name: 'Isiala Ngwa South', id: 7 },
            { name: 'Isuikwuato', id: 8 },
            { name: 'Obi Ngwa', id: 9 },
            { name: 'Ohafia', id: 10 },
            { name: 'Osisioma Ngwa', id: 11 },
            { name: 'Ugwunagbo', id: 12 },
            { name: 'Ukwa East', id: 13 },
            { name: 'Ukwa West', id: 14 },
            { name: 'Umuahia North', id: 15 },
            { name: 'Umuahia South', id: 16 },
            { name: 'Umu Nneochi', id: 17 }
          ]
        },
        {
          name: 'Adamawa State',
          id: 2,
          locals: [
            { name: 'Demsa', id: 1 },
            { name: 'Fufore', id: 2 },
            { name: 'Ganye', id: 3 },
            { name: 'Girei', id: 4 },
            { name: 'Gombi', id: 5 },
            { name: 'Guyuk', id: 6 },
            { name: 'Hong', id: 7 },
            { name: 'Jada', id: 8 },
            { name: 'Lamurde', id: 9 },
            { name: 'Madagali', id: 10 },
            { name: 'Maiha', id: 11 },
            { name: 'Mayo Belwa', id: 12 },
            { name: 'Michika', id: 13 },
            { name: 'Mubi North', id: 14 },
            { name: 'Mubi South', id: 15 },
            { name: 'Numan', id: 16 },
            { name: 'Shelleng', id: 17 },
            { name: 'Song', id: 18 },
            { name: 'Toungo', id: 19 },
            { name: 'Yola North', id: 20 },
            { name: 'Yola South', id: 21 }
          ]
        },
        {
          name: 'Akwa Ibom State',
          id: 3,
          locals: [
            { name: 'Abak', id: 1 },
            { name: 'Eastern Obolo', id: 2 },
            { name: 'Eket', id: 3 },
            { name: 'Esit Eket', id: 4 },
            { name: 'Essien Udim', id: 5 },
            { name: 'Etim Ekpo', id: 6 },
            { name: 'Etinan', id: 7 },
            { name: 'Ibeno', id: 8 },
            { name: 'Ibesikpo Asutan', id: 9 },
            { name: 'Ibiono-Ibom', id: 10 },
            { name: 'Ika', id: 11 },
            { name: 'Ikono', id: 12 },
            { name: 'Ikot Abasi', id: 13 },
            { name: 'Ikot Ekpene', id: 14 },
            { name: 'Ini', id: 15 },
            { name: 'Itu', id: 16 },
            { name: 'Mbo', id: 17 },
            { name: 'Mkpat-Enin', id: 17 },
            { name: 'Nsit-Atai', id: 18 },
            { name: 'Nsit-Ibom', id: 20 },
            { name: 'Nsit-Ubium', id: 21 },
            { name: 'Obot Akara', id: 22 },
            { name: 'Okobo', id: 23 },
            { name: 'Onna', id: 24 },
            { name: 'Oron', id: 25 },
            { name: 'Oruk Anam', id: 26 },
            { name: 'Udung-Uko', id: 27 },
            { name: 'Ukanafun', id: 28 },
            { name: 'Uruan', id: 29 },
            { name: 'Urue-Offong/Oruko', id: 30 },
            { name: 'Uyo', id: 31 }
          ]
        },
        {
          name: 'Anambra State',
          id: 4,
          locals: [
            { name: 'Aguata', id: 1 },
            { name: 'Anambra East', id: 2 },
            { name: 'Anambra West', id: 3 },
            { name: 'Anaocha', id: 4 },
            { name: 'Awka North', id: 5 },
            { name: 'Awka South', id: 6 },
            { name: 'Ayamelum', id: 7 },
            { name: 'Dunukofia', id: 8 },
            { name: 'Ekwusigo', id: 9 },
            { name: 'Idemili North', id: 10 },
            { name: 'Idemili South', id: 11 },
            { name: 'Ihiala', id: 12 },
            { name: 'Njikoka', id: 13 },
            { name: 'Nnewi North', id: 14 },
            { name: 'Nnewi South', id: 15 },
            { name: 'Ogbaru', id: 16 },
            { name: 'Onitsha North', id: 17 },
            { name: 'Onitsha South', id: 18 },
            { name: 'Orumba North', id: 19 },
            { name: 'Orumba South', id: 20 },
            { name: 'Oyi', id: 21 }
          ]
        },
        {
          name: 'Bauchi State',
          id: 5,
          locals: [
            { name: 'Alkaleri', id: 1 },
            { name: 'Bauchi', id: 2 },
            { name: 'Bogoro', id: 3 },
            { name: 'Damban', id: 4 },
            { name: 'Darazo', id: 5 },
            { name: 'Dass', id: 6 },
            { name: 'Gamawa', id: 7 },
            { name: 'Ganjuwa', id: 8 },
            { name: 'Giade', id: 9 },
            { name: 'Itas/Gadau', id: 10 },
            { name: "Jama'are", id: 11 },
            { name: 'Katagum', id: 12 },
            { name: 'Kirfi', id: 13 },
            { name: 'Misau', id: 14 },
            { name: 'Ningi', id: 15 },
            { name: 'Shira', id: 16 },
            { name: 'Tafawa Balewa', id: 17 },
            { name: 'Toro', id: 18 },
            { name: 'Warji', id: 19 },
            { name: 'Zaki', id: 20 }
          ]
        },
        {
          name: 'Bayelsa State',
          id: 6,
          locals: [
            { name: 'Brass', id: 1 },
            { name: 'Ekeremor', id: 2 },
            { name: 'Kolokuma/Opokuma', id: 3 },
            { name: 'Nembe', id: 4 },
            { name: 'Ogbia', id: 5 },
            { name: 'Sagbama', id: 6 },
            { name: 'Southern Ijaw', id: 7 },
            { name: 'Yenagoa', id: 8 }
          ]
        },
        {
          name: 'Benue State',
          id: 7,
          locals: [
            { name: 'Apa', id: 1 },
            { name: 'Ado', id: 2 },
            { name: 'Agatu', id: 3 },
            { name: 'Buruku', id: 4 },
            { name: 'Gboko', id: 5 },
            { name: 'Guma', id: 6 },
            { name: 'Gwer East', id: 7 },
            { name: 'Gwer West', id: 8 },
            { name: 'Katsina-Ala', id: 9 },
            { name: 'Konshisha', id: 10 },
            { name: 'Kwande', id: 11 },
            { name: 'Logo', id: 12 },
            { name: 'Makurdi', id: 13 },
            { name: 'Obi', id: 14 },
            { name: 'Ogbadibo', id: 15 },
            { name: 'Ohimini', id: 16 },
            { name: 'Oju', id: 17 },
            { name: 'Okpokwu', id: 18 },
            { name: 'Oturkpo', id: 19 },
            { name: 'Tarka', id: 20 },
            { name: 'Ukum', id: 21 },
            { name: 'Ushongo', id: 22 },
            { name: 'Vandeikya', id: 23 }
          ]
        },
        {
          name: 'Borno State',
          id: 8,
          locals: [
            { name: 'Abadam', id: 1 },
            { name: 'Askira/Uba', id: 1 },
            { name: 'Bama', id: 2 },
            { name: 'Bayo', id: 3 },
            { name: 'Biu', id: 4 },
            { name: 'Chibok', id: 5 },
            { name: 'Damboa', id: 6 },
            { name: 'Dikwa', id: 7 },
            { name: 'Gubio', id: 8 },
            { name: 'Guzamala', id: 9 },
            { name: 'Gwoza', id: 10 },
            { name: 'Hawul', id: 11 },
            { name: 'Jere', id: 12 },
            { name: 'Kaga', id: 13 },
            { name: 'Kala/Balge', id: 14 },
            { name: 'Konduga', id: 15 },
            { name: 'Kukawa', id: 16 },
            { name: 'Kwaya Kusar', id: 17 },
            { name: 'Mafa', id: 18 },
            { name: 'Magumeri', id: 19 },
            { name: 'Maiduguri', id: 20 },
            { name: 'Marte', id: 21 },
            { name: 'Mobbar', id: 22 },
            { name: 'Monguno', id: 23 },
            { name: 'Ngala', id: 24 },
            { name: 'Nganzai', id: 25 },
            { name: 'Shani', id: 26 }
          ]
        },
        {
          name: 'Cross River State',
          id: 9,
          locals: [
            { name: 'Abi', id: 1 },
            { name: 'Akamkpa', id: 2 },
            { name: 'Akpabuyo', id: 3 },
            { name: 'Bakassi', id: 4 },
            { name: 'Bekwarra', id: 5 },
            { name: 'Biase', id: 6 },
            { name: 'Boki', id: 7 },
            { name: 'Calabar Municipal', id: 8 },
            { name: 'Calabar South', id: 9 },
            { name: 'Etung', id: 10 },
            { name: 'Ikom', id: 11 },
            { name: 'Obanliku', id: 12 },
            { name: 'Obubra', id: 13 },
            { name: 'Obudu', id: 14 },
            { name: 'Odukpani', id: 15 },
            { name: 'Ogoja', id: 16 },
            { name: 'Yakuur', id: 17 },
            { name: 'Yala', id: 18 }
          ]
        },
        {
          name: 'Delta State',
          id: 10,
          locals: [
            { name: 'Aniocha North', id: 1 },
            { name: 'Aniocha South', id: 2 },
            { name: 'Bomadi', id: 3 },
            { name: 'Burutu', id: 4 },
            { name: 'Ethiope East', id: 5 },
            { name: 'Ethiope West', id: 6 },
            { name: 'Ika North East', id: 7 },
            { name: 'Ika South', id: 8 },
            { name: 'Isoko North', id: 9 },
            { name: 'Isoko South', id: 10 },
            { name: 'Ndokwa East', id: 11 },
            { name: 'Ndokwa West', id: 12 },
            { name: 'Okpe', id: 13 },
            { name: 'Oshimili North', id: 14 },
            { name: 'Oshimili South', id: 15 },
            { name: 'Patani', id: 16 },
            { name: 'Sapele', id: 17 },
            { name: 'Udu', id: 18 },
            { name: 'Ughelli North', id: 19 },
            { name: 'Ughelli South', id: 20 },
            { name: 'Ukwuani', id: 21 },
            { name: 'Uvwie', id: 22 },
            { name: 'Warri North', id: 23 },
            { name: 'Warri South', id: 24 },
            { name: 'Warri South West', id: 25 }
          ]
        },
        {
          name: 'Ebonyi State',
          id: 11,
          locals: [
            { name: 'Abakaliki', id: 1 },
            { name: 'Afikpo North', id: 2 },
            { name: 'Afikpo South', id: 3 },
            { name: 'Ebonyi', id: 4 },
            { name: 'Ezza North', id: 5 },
            { name: 'Ezza South', id: 6 },
            { name: 'Ikwo', id: 7 },
            { name: 'Ishielu', id: 8 },
            { name: 'Ivo', id: 9 },
            { name: 'Izzi', id: 10 },
            { name: 'Ohaozara', id: 11 },
            { name: 'Ohaukwu', id: 12 },
            { name: 'Onicha', id: 13 }
          ]
        },
        {
          name: 'Edo State',
          id: 12,
          locals: [
            { name: 'Akoko Edo', id: 1 },
            { name: 'Egor', id: 2 },
            { name: 'Esan Central', id: 3 },
            { name: 'Esan North-East', id: 4 },
            { name: 'Esan South-East', id: 5 },
            { name: 'Esan West', id: 6 },
            { name: 'Etsako Central', id: 7 },
            { name: 'Etsako East', id: 8 },
            { name: 'Etsako West', id: 9 },
            { name: 'Igueben', id: 10 },
            { name: 'Ikpoba Okha', id: 11 },
            { name: 'Orhionmwon', id: 12 },
            { name: 'Oredo', id: 13 },
            { name: 'Ovia North-East', id: 14 },
            { name: 'Ovia South-West', id: 15 },
            { name: 'Owan East', id: 16 },
            { name: 'Owan West', id: 17 },
            { name: 'Uhunmwonde', id: 18 }
          ]
        },
        {
          name: 'Ekiti State',
          id: 13,
          locals: [
            { name: 'Afikpo South', id: 1 },
            { name: 'Efon', id: 2 },
            { name: 'Ekiti East', id: 3 },
            { name: 'Ekiti South-West', id: 4 },
            { name: 'Ekiti West', id: 5 },
            { name: 'Emure', id: 6 },
            { name: 'Gbonyin', id: 7 },
            { name: 'Ido Osi', id: 8 },
            { name: 'Ijero', id: 9 },
            { name: 'Ikere', id: 10 },
            { name: 'Ikole', id: 11 },
            { name: 'Ilejemeje', id: 12 },
            { name: 'Irepodun/Ifelodun', id: 13 },
            { name: 'Ise/Orun', id: 14 },
            { name: 'Moba', id: 15 },
            { name: 'Oye', id: 16 }
          ]
        },
        {
          name: 'Enugu State',
          id: 14,
          locals: [
            { name: 'Aninri', id: 1 },
            { name: 'Awgu', id: 2 },
            { name: 'Enugu East', id: 3 },
            { name: 'Enugu North', id: 4 },
            { name: 'Enugu South', id: 5 },
            { name: 'Ezeagu', id: 6 },
            { name: 'Igbo Etiti', id: 7 },
            { name: 'Igbo Eze North', id: 8 },
            { name: 'Igbo Eze South', id: 9 },
            { name: 'Isi Uzo', id: 10 },
            { name: 'Nkanu East', id: 11 },
            { name: 'Nkanu West', id: 12 },
            { name: 'Nsukka', id: 13 },
            { name: 'Oji River', id: 14 },
            { name: 'Udenu', id: 15 },
            { name: 'Udi', id: 16 },
            { name: 'Uzo Uwani', id: 17 }
          ]
        },
        {
          name: 'FCT',
          id: 15,
          locals: [
            { name: 'Abaji', id: 1 },
            { name: 'Bwari', id: 2 },
            { name: 'Gwagwalada', id: 3 },
            { name: 'Kuje', id: 4 },
            { name: 'Kwali', id: 5 },
            { name: 'Municipal Area Council', id: 6 }
          ]
        },
        {
          name: 'Gombe State',
          id: 16,
          locals: [
            { name: 'Akko', id: 1 },
            { name: 'Balanga', id: 2 },
            { name: 'Billiri', id: 3 },
            { name: 'Dukku', id: 4 },
            { name: 'Funakaye', id: 5 },
            { name: 'Gombe', id: 6 },
            { name: 'Kaltungo', id: 7 },
            { name: 'Kwami', id: 8 },
            { name: 'Nafada', id: 9 },
            { name: 'Shongom', id: 10 },
            { name: 'Yamaltu/Deba', id: 11 }
          ]
        },
        {
          name: 'Imo State',
          id: 17,
          locals: [
            { name: 'Aboh Mbaise', id: 1 },
            { name: 'Ahiazu Mbaise', id: 2 },
            { name: 'Ehime Mbano', id: 3 },
            { name: 'Ezinihitte', id: 4 },
            { name: 'Ideato North', id: 5 },
            { name: 'Ideato South', id: 6 },
            { name: 'Ihitte/Uboma', id: 7 },
            { name: 'Ikeduru', id: 8 },
            { name: 'Isiala Mbano', id: 9 },
            { name: 'Isu', id: 10 },
            { name: 'Mbatoli', id: 11 },
            { name: 'Ngor Okpala', id: 12 },
            { name: 'Njaba', id: 13 },
            { name: 'Nkwerre', id: 14 },
            { name: 'Nwangele', id: 15 },
            { name: 'Obowo', id: 16 },
            { name: 'Oguta', id: 17 },
            { name: 'Ohaji/Egbema', id: 18 },
            { name: 'Okigwe', id: 19 },
            { name: 'Orlu', id: 20 },
            { name: 'Orsu', id: 21 },
            { name: 'Oru East', id: 22 },
            { name: 'Oru West', id: 23 },
            { name: 'Owerri Municipal', id: 24 },
            { name: 'Owerri North', id: 25 },
            { name: 'Owerri West', id: 26 },
            { name: 'Onuimo', id: 27 }
          ]
        },
        {
          name: 'Jigawa State',
          id: 18,
          locals: [
            { name: 'Auyo', id: 1 },
            { name: 'Babura', id: 2 },
            { name: 'Biriniwa', id: 3 },
            { name: 'Birnin Kudu', id: 4 },
            { name: 'Buji', id: 5 },
            { name: 'Dutse', id: 6 },
            { name: 'Gagarawa', id: 7 },
            { name: 'Garki', id: 8 },
            { name: 'Gumel', id: 9 },
            { name: 'Guri', id: 10 },
            { name: 'Gwaram', id: 11 },
            { name: 'Gwiwa', id: 12 },
            { name: 'Hadejia', id: 13 },
            { name: 'Jahun', id: 14 },
            { name: 'Kafin Hausa', id: 15 },
            { name: 'Kazaure', id: 16 },
            { name: 'Kiri Kasama', id: 17 },
            { name: 'Kiyawa', id: 18 },
            { name: 'Kaugama', id: 19 },
            { name: 'Maigatari', id: 20 },
            { name: 'Malam Maduri', id: 21 },
            { name: 'Miga', id: 22 },
            { name: 'Ringim', id: 23 },
            { name: 'Roni', id: 24 },
            { name: 'Sule Tankarkar', id: 25 },
            { name: 'Taura', id: 26 },
            { name: 'Yankwashi', id: 27 }
          ]
        },
        {
          name: 'Kaduna State',
          id: 19,
          locals: [
            { name: 'Birnin Gwari', id: 1 },
            { name: 'Chikun', id: 2 },
            { name: 'Giwa', id: 3 },
            { name: 'Igabi', id: 4 },
            { name: 'Ikara', id: 5 },
            { name: 'Jaba', id: 6 },
            { name: "Jema'a", id: 7 },
            { name: 'Kachia', id: 8 },
            { name: 'Kaduna North', id: 9 },
            { name: 'Kaduna South', id: 10 },
            { name: 'Kagarko', id: 11 },
            { name: 'Kajuru', id: 12 },
            { name: 'Kaura', id: 13 },
            { name: 'Kauru', id: 14 },
            { name: 'Kubau', id: 15 },
            { name: 'Kudan', id: 16 },
            { name: 'Lere', id: 17 },
            { name: 'Makarfi', id: 18 },
            { name: 'Sabon Gari', id: 19 },
            { name: 'Sanga', id: 20 },
            { name: 'Soba', id: 21 },
            { name: 'Zangon Kataf', id: 22 },
            { name: 'Zaria', id: 23 }
          ]
        },
        {
          name: 'Kano State',
          id: 20,
          locals: [
            { name: 'Ajingi', id: 1 },
            { name: 'Albasu', id: 2 },
            { name: 'Bagwai', id: 3 },
            { name: 'Bebeji', id: 4 },
            { name: 'Bichi', id: 5 },
            { name: 'Bunkure', id: 6 },
            { name: 'Dala', id: 7 },
            { name: 'Dambatta', id: 8 },
            { name: 'Dawakin Kudu', id: 9 },
            { name: 'Dawakin Tofa', id: 10 },
            { name: 'Doguwa', id: 11 },
            { name: 'Fagge', id: 12 },
            { name: 'Gabasawa', id: 13 },
            { name: 'Garko', id: 14 },
            { name: 'Garum Mallam', id: 15 },
            { name: 'Gaya', id: 16 },
            { name: 'Gezawa', id: 17 },
            { name: 'Gwale', id: 18 },
            { name: 'Gwarzo', id: 19 },
            { name: 'Kabo', id: 20 },
            { name: 'Kano Municipal', id: 21 },
            { name: 'Karaye', id: 22 },
            { name: 'Kibiya', id: 23 },
            { name: 'Kiru', id: 24 },
            { name: 'Kumbotso', id: 25 },
            { name: 'Kunchi', id: 26 },
            { name: 'Kura', id: 27 },
            { name: 'Madobi', id: 28 },
            { name: 'Makoda', id: 29 },
            { name: 'Minjibir', id: 30 },
            { name: 'Nasarawa', id: 31 },
            { name: 'Rano', id: 32 },
            { name: 'Rimin Gado', id: 33 },
            { name: 'Rogo', id: 34 },
            { name: 'Shanono', id: 35 },
            { name: 'Sumaila', id: 36 },
            { name: 'Takai', id: 37 },
            { name: 'Tarauni', id: 38 },
            { name: 'Tofa', id: 39 },
            { name: 'Tsanyawa', id: 40 },
            { name: 'Tudun Wada', id: 41 },
            { name: 'Ungogo', id: 42 },
            { name: 'Warawa', id: 43 },
            { name: 'Wudil', id: 44 }
          ]
        },
        {
          name: 'Katsina State',
          id: 21,
          locals: [
            { name: 'Bakori', id: 1 },
            { name: 'Batagarawa', id: 2 },
            { name: 'Batsari', id: 3 },
            { name: 'Baure', id: 4 },
            { name: 'Bindawa', id: 5 },
            { name: 'Charanchi', id: 6 },
            { name: 'Dandume', id: 7 },
            { name: 'Danja', id: 8 },
            { name: 'Dan Musa', id: 9 },
            { name: 'Daura', id: 10 },
            { name: 'Dutsi', id: 11 },
            { name: 'Dutsin Ma', id: 12 },
            { name: 'Faskari', id: 13 },
            { name: 'Funtua', id: 14 },
            { name: 'Ingawa', id: 15 },
            { name: 'Jibia', id: 16 },
            { name: 'Kafur', id: 17 },
            { name: 'Kaita', id: 18 },
            { name: 'Kankara', id: 19 },
            { name: 'Kankia', id: 20 },
            { name: 'Katsina', id: 21 },
            { name: 'Kurfi', id: 22 },
            { name: 'Kusada', id: 23 },
            { name: "Mai'Adua", id: 24 },
            { name: 'Malumfashi', id: 25 },
            { name: 'Mani', id: 26 },
            { name: 'Mashi', id: 27 },
            { name: 'Matazu', id: 28 },
            { name: 'Musawa', id: 29 },
            { name: 'Rimi', id: 30 },
            { name: 'Sabuwa', id: 31 },
            { name: 'Safana', id: 32 },
            { name: 'Sandamu', id: 33 },
            { name: 'Zango', id: 34 }
          ]
        },
        {
          name: 'Kebbi State',
          id: 22,
          locals: [
            { name: 'Aleiro', id: 1 },
            { name: 'Arewa Dandi', id: 2 },
            { name: 'Argungu', id: 3 },
            { name: 'Augie', id: 4 },
            { name: 'Bagudo', id: 5 },
            { name: 'Birnin Kebbi', id: 6 },
            { name: 'Bunza', id: 7 },
            { name: 'Dandi', id: 8 },
            { name: 'Fakai', id: 9 },
            { name: 'Gwandu', id: 10 },
            { name: 'Jega', id: 11 },
            { name: 'Kalgo', id: 12 },
            { name: 'Koko/Besse', id: 13 },
            { name: 'Maiyama', id: 14 },
            { name: 'Ngaski', id: 15 },
            { name: 'Sakaba', id: 16 },
            { name: 'Shanga', id: 17 },
            { name: 'Suru', id: 18 },
            { name: 'Wasagu/Danko', id: 19 },
            { name: 'Yauri', id: 20 },
            { name: 'Zuru', id: 21 }
          ]
        },
        {
          name: 'Kogi State',
          id: 23,
          locals: [
            { name: 'Adavi', id: 1 },
            { name: 'Ajaokuta', id: 2 },
            { name: 'Ankpa', id: 3 },
            { name: 'Bassa', id: 4 },
            { name: 'Dekina', id: 5 },
            { name: 'Ibaji', id: 6 },
            { name: 'Idah', id: 7 },
            { name: 'Igalamela Odolu', id: 8 },
            { name: 'Ijumu', id: 9 },
            { name: 'Kabba/Bunu', id: 10 },
            { name: 'Kogi', id: 11 },
            { name: 'Lokoja', id: 12 },
            { name: 'Mopa Muro', id: 13 },
            { name: 'Ofu', id: 14 },
            { name: 'Ogori/Magongo', id: 15 },
            { name: 'Okehi', id: 16 },
            { name: 'Okene', id: 17 },
            { name: 'Olamaboro', id: 18 },
            { name: 'Omala', id: 19 },
            { name: 'Yagba East', id: 20 },
            { name: 'Yagba West', id: 21 }
          ]
        },
        {
          name: 'Kwara State',
          id: 24,
          locals: [
            { name: 'Asa', id: 1 },
            { name: 'Baruten', id: 2 },
            { name: 'Edu', id: 3 },
            { name: 'Ekiti', id: 4 },
            { name: 'Ifelodun', id: 5 },
            { name: 'Ilorin East', id: 6 },
            { name: 'Ilorin South', id: 7 },
            { name: 'Ilorin West', id: 8 },
            { name: 'Irepodun', id: 9 },
            { name: 'Isin', id: 10 },
            { name: 'Kaiama', id: 11 },
            { name: 'Moro', id: 12 },
            { name: 'Offa', id: 13 },
            { name: 'Oke Ero', id: 14 },
            { name: 'Oyun', id: 15 },
            { name: 'Pategi', id: 16 }
          ]
        },
        {
          name: 'Lagos State',
          id: 25,
          locals: [
            { name: 'Agege', id: 1 },
            { name: 'Ajeromi-Ifelodun', id: 2 },
            { name: 'Alimosho', id: 3 },
            { name: 'Amuwo-Odofin', id: 4 },
            { name: 'Apapa', id: 5 },
            { name: 'Badagry', id: 6 },
            { name: 'Epe', id: 7 },
            { name: 'Eti Osa', id: 8 },
            { name: 'Ibeju-Lekki', id: 9 },
            { name: 'Ifako-Ijaye', id: 10 },
            { name: 'Ikeja', id: 11 },
            { name: 'Ikorodu', id: 12 },
            { name: 'Kosofe', id: 13 },
            { name: 'Lagos Island', id: 14 },
            { name: 'Lagos Mainland', id: 15 },
            { name: 'Mushin', id: 16 },
            { name: 'Ojo', id: 17 },
            { name: 'Oshodi-Isolo', id: 18 },
            { name: 'Shomolu', id: 19 },
            { name: 'Surulere', id: 20 }
          ]
        },
        {
          name: 'Nasarawa State',
          id: 26,
          locals: [
            { name: 'Akwanga', id: 1 },
            { name: 'Awe', id: 2 },
            { name: 'Doma', id: 3 },
            { name: 'Karu', id: 4 },
            { name: 'Keana', id: 5 },
            { name: 'Keffi', id: 6 },
            { name: 'Kokona', id: 7 },
            { name: 'Lafia', id: 8 },
            { name: 'Nasarawa', id: 9 },
            { name: 'Nasarawa Egon', id: 10 },
            { name: 'Obi', id: 11 },
            { name: 'Toto', id: 12 },
            { name: 'Wamba', id: 13 }
          ]
        },
        {
          name: 'Niger State',
          id: 27,
          locals: [
            { name: 'Agaie', id: 1 },
            { name: 'Agwara', id: 2 },
            { name: 'Bida', id: 3 },
            { name: 'Borgu', id: 4 },
            { name: 'Bosso', id: 5 },
            { name: 'Chanchaga', id: 6 },
            { name: 'Edati', id: 7 },
            { name: 'Gbako', id: 8 },
            { name: 'Gurara', id: 9 },
            { name: 'Katcha', id: 10 },
            { name: 'Kontagora', id: 11 },
            { name: 'Lapai', id: 12 },
            { name: 'Lavun', id: 13 },
            { name: 'Magama', id: 14 },
            { name: 'Mariga', id: 15 },
            { name: 'Mashegu', id: 16 },
            { name: 'Mokwa', id: 17 },
            { name: 'Munya', id: 18 },
            { name: 'Paikoro', id: 19 },
            { name: 'Rafi', id: 20 },
            { name: 'Rijau', id: 21 },
            { name: 'Shiroro', id: 22 },
            { name: 'Suleja', id: 23 },
            { name: 'Tafa', id: 24 },
            { name: 'Wushishi', id: 25 }
          ]
        },
        {
          name: 'Ogun State',
          id: 28,
          locals: [
            { name: 'Abeokuta North', id: 1 },
            { name: 'Abeokuta South', id: 2 },
            { name: 'Ado-Odo/Ota', id: 3 },
            { name: 'Egbado North', id: 4 },
            { name: 'Egbado South', id: 5 },
            { name: 'Ewekoro', id: 6 },
            { name: 'Ifo', id: 7 },
            { name: 'Ijebu East', id: 8 },
            { name: 'Ijebu North', id: 9 },
            { name: 'Ijebu North East', id: 10 },
            { name: 'Ijebu Ode', id: 11 },
            { name: 'Ikenne', id: 12 },
            { name: 'Imeko Afon', id: 13 },
            { name: 'Ipokia', id: 14 },
            { name: 'Obafemi Owode', id: 15 },
            { name: 'Odeda', id: 16 },
            { name: 'Odogbolu', id: 17 },
            { name: 'Ogun Waterside', id: 18 },
            { name: 'Remo North', id: 19 },
            { name: 'Shagamu', id: 20 },
            { name: 'Yewa North', id: 21 },
            { name: 'Yewa South', id: 22 }
          ]
        },
        {
          name: 'Ondo State',
          id: 29,
          locals: [
            { name: 'Akoko North-East', id: 1 },
            { name: 'Akoko North-West', id: 2 },
            { name: 'Akoko South-East', id: 3 },
            { name: 'Akoko South-West', id: 4 },
            { name: 'Akure North', id: 5 },
            { name: 'Akure South', id: 6 },
            { name: 'Ese Odo', id: 7 },
            { name: 'Idanre', id: 8 },
            { name: 'Ifedore', id: 9 },
            { name: 'Ilaje', id: 10 },
            { name: 'Ile Oluji/Okeigbo', id: 11 },
            { name: 'Irele', id: 12 },
            { name: 'Odigbo', id: 13 },
            { name: 'Okitipupa', id: 14 },
            { name: 'Ondo East', id: 15 },
            { name: 'Ondo West', id: 16 },
            { name: 'Ose', id: 17 },
            { name: 'Owo', id: 18 }
          ]
        },
        {
          name: 'Osun State',
          id: 30,
          locals: [
            { name: 'Atakunmosa East', id: 1 },
            { name: 'Atakunmosa West', id: 2 },
            { name: 'Aiyedaade', id: 3 },
            { name: 'Aiyedire', id: 4 },
            { name: 'Boluwaduro', id: 5 },
            { name: 'Boripe', id: 6 },
            { name: 'Ede North', id: 7 },
            { name: 'Ede South', id: 8 },
            { name: 'Egbedore', id: 9 },
            { name: 'Ejigbo', id: 10 },
            { name: 'Ife Central', id: 11 },
            { name: 'Ife East', id: 12 },
            { name: 'Ife North', id: 13 },
            { name: 'Ife South', id: 14 },
            { name: 'Ifedayo', id: 15 },
            { name: 'Ifelodun', id: 16 },
            { name: 'Ila', id: 17 },
            { name: 'Ilesa East', id: 18 },
            { name: 'Ilesa West', id: 19 },
            { name: 'Irepodun', id: 20 },
            { name: 'Irewole', id: 21 },
            { name: 'Isokan', id: 22 },
            { name: 'Iwo', id: 23 },
            { name: 'Obokun', id: 24 },
            { name: 'Odo Otin', id: 25 },
            { name: 'Ola Oluwa', id: 26 },
            { name: 'Olorunda', id: 27 },
            { name: 'Oriade', id: 28 },
            { name: 'Orolu', id: 29 },
            { name: 'Osogbo', id: 30 }
          ]
        },
        {
          name: 'Oyo State',
          id: 31,
          locals: [
            { name: 'Afijio', id: 1 },
            { name: 'Akinyele', id: 2 },
            { name: 'Atiba', id: 3 },
            { name: 'Atisbo', id: 4 },
            { name: 'Egbeda', id: 5 },
            { name: 'Ibadan North', id: 6 },
            { name: 'Ibadan North-East', id: 7 },
            { name: 'Ibadan North-West', id: 8 },
            { name: 'Ibadan South-East', id: 9 },
            { name: 'Ibadan South-West', id: 10 },
            { name: 'Ibarapa Central', id: 11 },
            { name: 'Ibarapa East', id: 12 },
            { name: 'Ibarapa North', id: 13 },
            { name: 'Ido', id: 14 },
            { name: 'Irepo', id: 15 },
            { name: 'Iseyin', id: 16 },
            { name: 'Itesiwaju', id: 17 },
            { name: 'Iwajowa', id: 18 },
            { name: 'Kajola', id: 19 },
            { name: 'Lagelu', id: 20 },
            { name: 'Ogbomosho North', id: 21 },
            { name: 'Ogbomosho South', id: 22 },
            { name: 'Ogo Oluwa', id: 23 },
            { name: 'Olorunsogo', id: 24 },
            { name: 'Oluyole', id: 25 },
            { name: 'Ona Ara', id: 26 },
            { name: 'Orelope', id: 27 },
            { name: 'Ori Ire', id: 28 },
            { name: 'Oyo', id: 29 },
            { name: 'Oyo East', id: 30 },
            { name: 'Oyo West', id: 31 },
            { name: 'Saki East', id: 32 },
            { name: 'Saki West', id: 33 },
            { name: 'Surulere', id: 34 }
          ]
        },
        {
          name: 'Plateau State',
          id: 32,
          locals: [
            { name: 'Barkin Ladi', id: 1 },
            { name: 'Bassa', id: 2 },
            { name: 'Bokkos', id: 3 },
            { name: 'Jos East', id: 4 },
            { name: 'Jos North', id: 5 },
            { name: 'Jos South', id: 6 },
            { name: 'Kanam', id: 7 },
            { name: 'Kanke', id: 8 },
            { name: 'Langtang South', id: 9 },
            { name: 'Langtang North', id: 10 },
            { name: 'Mangu', id: 11 },
            { name: 'Mikang', id: 12 },
            { name: 'Pankshin', id: 13 },
            { name: "Qua'an Pan", id: 14 },
            { name: 'Riyom', id: 15 },
            { name: 'Shendam', id: 16 },
            { name: 'Wase', id: 17 }
          ]
        },
        {
          name: 'Rivers State',
          id: 33,
          locals: [
            { name: 'Abua Odual', id: 1 },
            { name: 'Ahoada East', id: 2 },
            { name: 'Ahoada West', id: 3 },
            { name: 'Akuku-Toru', id: 4 },
            { name: 'Andoni', id: 5 },
            { name: 'Asari-Toru', id: 6 },
            { name: 'Bonny', id: 7 },
            { name: 'Degema', id: 8 },
            { name: 'Eleme', id: 9 },
            { name: 'Emuoha', id: 10 },
            { name: 'Etche', id: 11 },
            { name: 'Gokana', id: 12 },
            { name: 'Ikwerre', id: 13 },
            { name: 'Khana', id: 14 },
            { name: 'Obio/Akpor', id: 15 },
            { name: 'Ogba/Egbema/Ndoni', id: 16 },
            { name: 'Ogu/Bolo', id: 17 },
            { name: 'Okrika', id: 18 },
            { name: 'Omumma', id: 19 },
            { name: 'Opobo/Nkoro', id: 20 },
            { name: 'Oyigbo', id: 21 },
            { name: 'Port Harcourt', id: 22 },
            { name: 'Tai', id: 23 }
          ]
        },
        {
          name: 'Sokoto State',
          id: 34,
          locals: [
            { name: 'Binji', id: 1 },
            { name: 'Bodinga', id: 2 },
            { name: 'Dange Shuni', id: 3 },
            { name: 'Gada', id: 4 },
            { name: 'Goronyo', id: 5 },
            { name: 'Gudu', id: 6 },
            { name: 'Gwadabawa', id: 7 },
            { name: 'Illela', id: 8 },
            { name: 'Isa', id: 9 },
            { name: 'Kebbe', id: 10 },
            { name: 'Kware', id: 11 },
            { name: 'Rabah', id: 12 },
            { name: 'Sabon Birni', id: 13 },
            { name: 'Shagari', id: 14 },
            { name: 'Silame', id: 15 },
            { name: 'Sokoto North', id: 16 },
            { name: 'Sokoto South', id: 17 },
            { name: 'Tambuwal', id: 18 },
            { name: 'Tangaza', id: 19 },
            { name: 'Tureta', id: 20 },
            { name: 'Wamakko', id: 21 },
            { name: 'Wurno', id: 22 },
            { name: 'Yabo', id: 23 }
          ]
        },
        {
          name: 'Taraba State',
          id: 35,
          locals: [
            { name: 'Ardo Kola', id: 1 },
            { name: 'Bali', id: 2 },
            { name: 'Donga', id: 3 },
            { name: 'Gashaka', id: 4 },
            { name: 'Gassol', id: 5 },
            { name: 'Ibi', id: 6 },
            { name: 'Jalingo', id: 7 },
            { name: 'Karim Lamido', id: 8 },
            { name: 'Kurmi', id: 9 },
            { name: 'Lau', id: 10 },
            { name: 'Sardauna', id: 11 },
            { name: 'Takum', id: 12 },
            { name: 'Ussa', id: 13 },
            { name: 'Wukari', id: 14 },
            { name: 'Yorro', id: 15 },
            { name: 'Zing', id: 16 }
          ]
        },
        {
          name: 'Yobe State',
          id: 36,
          locals: [
            { name: 'Bade', id: 1 },
            { name: 'Bursari', id: 2 },
            { name: 'Damaturu', id: 3 },
            { name: 'Fika', id: 4 },
            { name: 'Fune', id: 5 },
            { name: 'Geidam', id: 6 },
            { name: 'Gujba', id: 7 },
            { name: 'Gulani', id: 8 },
            { name: 'Jakusko', id: 9 },
            { name: 'Karasuwa', id: 10 },
            { name: 'Machina', id: 11 },
            { name: 'Nangere', id: 12 },
            { name: 'Nguru', id: 13 },
            { name: 'Potiskum', id: 14 },
            { name: 'Tarmuwa', id: 15 },
            { name: 'Yunusari', id: 16 },
            { name: 'Yusufari', id: 17 }
          ]
        },
        {
          name: 'Zamfara State',
          id: 37,
          locals: [
            { name: 'Anka', id: 1 },
            { name: 'Bakura', id: 2 },
            { name: 'Birnin Magaji/Kiyaw', id: 3 },
            { name: 'Bukkuyum', id: 4 },
            { name: 'Bungudu', id: 5 },
            { name: 'Gummi', id: 6 },
            { name: 'Gusau', id: 7 },
            { name: 'Kaura Namoda', id: 8 },
            { name: 'Maradun', id: 9 },
            { name: 'Maru', id: 10 },
            { name: 'Shinkafi', id: 11 },
            { name: 'Talata Mafara', id: 12 },
            { name: 'Tsafe', id: 13 },
            { name: 'Chafe', id: 14 },
            { name: 'Zurmi', id: 15 }
          ]
        }
      ],
      new_states: [],
      state: '',
      local_govts: [],
      new_local_govts: [],
      local_govt: '',
      local_search: '',
      state_search: '',
      show_state: false,
      show_local_govt: false
    }
  },
  watch: {
    state_search(val) {
      this.new_states = []
      this.searchStates(val)
    },
    local_search(val) {
      this.new_local_govts = []
      this.searchLocals(val)
    },
    state(val) {
      this.local_govts = []
      this.getLocalGovts(val)
    },
    local_govt(val) {
      if (this.local_govt != '' && this.state != '') {
        var state_lga = {
          state: this.state,
          local_govt: val
        }
        this.$emit('state-lga', state_lga)
      }
    },
    state_from_db(val) {
      if (val != undefined) {
        this.state = val
      }
    },
    local_govt_from_db(val) {
      if (val != undefined) {
        this.local_govt = val
      }
    }
  },
  methods: {
    selectState(val) {
      this.state = this.new_states[val].name
      this.show_state = false
      this.state_search = ''
    },
    searchStates(val) {
      this.states.forEach(state => {
        var state_name = state.name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (state_name.includes(lowercase_val)) {
          this.new_states.push(state)
        }
      })
    },
    selectLocalGovt(val) {
      this.local_govt = this.new_local_govts[val].name
      this.show_local_govt = false
      this.local_search = ''
    },
    getLocalGovts(val) {
      this.states.forEach(state => {
        if (state.name == val) {
          this.local_govts = state.locals
          this.new_local_govts = state.locals
        }
      })
    },
    searchLocals(val) {
      this.local_govts.forEach(local => {
        var local_name = local.name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (local_name.includes(lowercase_val)) {
          this.new_local_govts.push(local)
        }
      })
    }
  },
  mounted() {
    this.new_states = this.states
    if (this.local_govt_from_db != undefined) {
      this.local_govt = this.local_govt_from_db
    }
    if (this.state_from_db != undefined) {
      this.state = this.state_from_db
    }
  }
}
</script>

<style>
.localform-enter-active,
.localform-leave-active {
  transition: transition ease-in, opacity ease-in-out;
  @apply duration-300;
}

.localform-enter,
.localform-leave-to {
  opacity: 0;
}

.localform-leave,
.localform-enter-to {
  opacity: 1;
}
</style>
