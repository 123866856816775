<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-3/4 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">Chief Complaint</div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="space-y-6 md:w-9/12 lg:w-3/5 sm:mx-auto mt-20">
              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1 pla">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Date:
                  </h1>
                </div>

                <div class="col-span-2">
                  <datetime
                    v-model="chief_compliant.date"
                    class="
                      border-b border-nadis-ash2
                      w-full
                      px-2
                      py-3
                      font-medium
                      bg-nadis-bg2
                      focus:outline-none
                    "
                    placeholder="DD MM YYYY"
                  ></datetime>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Total Number of Animals:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="number of animals"
                    type="text"
                    v-model="chief_compliant.number_of_animals"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Total Number of Sick Animals:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    placeholder="number of sick animals"
                    type="text"
                    v-model="chief_compliant.number_of_sick_animals"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Total Number of Dead Animals:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-text
                    type="text"
                    placeholder="number of dead animals"
                    v-model="chief_compliant.number_of_dead_animals"
                  ></input-text>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    New Animal:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-select
                    v-on:add-unit="newAnimalUnit"
                    :unit_from_db="new_animal_unit_from_db"
                  ></input-select>
                </div>
              </div>
            </section>

            <section class="space-y-6 md:w-9/12 lg:w-3/5 sm:mx-auto mt-14">
              <div class="">
                <h1 class="text-xl">Indicate Recent Changes</h1>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Feed:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-select
                    v-on:add-unit="feedUnit"
                    :unit_from_db="feed_unit_from_db"
                  ></input-select>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-center">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Staff:
                  </h1>
                </div>

                <div class="col-span-2">
                  <input-select
                    v-on:add-unit="staffUnit"
                    :unit_from_db="staff_unit_from_db"
                  ></input-select>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Management / Previous Treatment:
                  </h1>
                </div>

                <div class="col-span-2">
                  <textarea
                    placeholder="Management / Previous Treatment"
                    cols="30"
                    rows="5"
                    class="
                      w-full
                      border border-gray-300
                      py-2
                      px-4
                      outline-none
                      rounded-md
                      text-base
                      focus:border-primary
                    "
                    v-model="chief_compliant.management_or_prev_treatment"
                  ></textarea>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:gap-x-6 sm:items-start">
                <div class="col-span-1">
                  <h1 class="text-lg text-gray-600 transition-opacity ease-in">
                    Response To Treatment:
                  </h1>
                </div>

                <div class="col-span-2">
                  <textarea
                    placeholder="Response To Treatment"
                    cols="30"
                    rows="5"
                    class="
                      w-full
                      border border-gray-300
                      py-2
                      px-4
                      outline-none
                      rounded-md
                      text-base
                      focus:border-primary
                    "
                    v-model="chief_compliant.response_to_treatment"
                  ></textarea>
                </div>
              </div>
            </section>

            <section class="flex justify-center mt-14" v-if="index == 1">
              <button
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
                @click="saveDoc"
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import { Datetime } from 'vue-datetime'
import closeIcon from '../../Icons/closeIcon.vue'
import InputText from '../../Utilities/InputText.vue'
import InputSelect from '../../Utilities/InputSelect.vue'
import Swal from 'sweetalert2'
export default {
  components: { closeIcon, Datetime, InputText, InputSelect },
  props: {
    report_id: {
      type: String
    },

    index: [Number, String],

    report: {
      type: [Object, Array]
    }
  },

  data() {
    return {
      check: false,
      chief_compliant: {
        date: null,
        number_of_animals: null,
        number_of_sick_animals: null,
        number_of_dead_animals: null,
        new_animal: null,
        feed: null,
        staff: null,
        management_or_prev_treatment: null,
        response_to_treatment: null
      },
      new_animal_unit_from_db: null,
      feed_unit_from_db: null,
      staff_unit_from_db: null,
      report_from_db: this.report
    }
  },

  watch: {
    data(val) {
      if (val == '') {
        this.chief_compliant.date = null
      }
    },

    number_of_animals(val) {
      if (val == '') {
        this.chief_compliant.number_of_animals = null
      }
    },

    number_of_sick_animals(val) {
      if (val == '') {
        this.chief_compliant.number_of_sick_animals = null
      }
    },

    number_of_dead_animals(val) {
      if (val == '') {
        this.chief_compliant.number_of_dead_animals = null
      }
    },

    new_animal(val) {
      if (val == '') {
        this.chief_compliant.new_animal = null
        this.new_animal_unit_from_db = null
      }
    },

    feed(val) {
      if (val == '') {
        this.chief_compliant.feed = null
        this.feed_unit_from_db = null
      }
    },

    staff(val) {
      if (val == '') {
        this.chief_compliant.staff = null
        this.staff_unit_from_db = null
      }
    },

    management_or_prev_treatment(val) {
      if (val == '') {
        this.chief_compliant.management_or_prev_treatment = null
      }
    },

    response_to_treatment(val) {
      if (val == '') {
        this.chief_compliant.response_to_treatment = null
      }
    },

    report_from_db(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.chief_compliant != undefined
      ) {
        if (val.medical.chief_compliant.data != undefined) {
          this.chief_compliant.date = val.medical.chief_compliant.data
        }

        if (val.medical.chief_compliant.number_of_animals != undefined) {
          this.chief_compliant.number_of_animals =
            val.medical.chief_compliant.number_of_animals
        }

        if (val.medical.chief_compliant.number_of_sick_animals != undefined) {
          this.chief_compliant.number_of_sick_animals =
            val.medical.chief_compliant.number_of_sick_animals
        }

        if (val.medical.chief_compliant.number_of_dead_animals != undefined) {
          this.chief_compliant.number_of_dead_animals =
            val.medical.chief_compliant.number_of_dead_animals
        }

        if (val.medical.chief_compliant.new_animal != undefined) {
          this.chief_compliant.new_animal =
            val.medical.chief_compliant.new_animal
          this.new_animal_unit_from_db = val.medical.chief_compliant.new_animal
        }

        if (val.medical.chief_compliant.feed != undefined) {
          this.chief_compliant.feed = val.medical.chief_compliant.feed
          this.feed_unit_from_db = val.medical.chief_compliant.feed
        }

        if (val.medical.chief_compliant.staff != undefined) {
          this.chief_compliant.staff = val.medical.chief_compliant.staff
          this.staff_unit_from_db = val.medical.chief_compliant.staff
        }

        if (
          val.medical.chief_compliant.management_or_prev_treatment != undefined
        ) {
          this.chief_compliant.management_or_prev_treatment =
            val.medical.chief_compliant.management_or_prev_treatment
        }

        if (val.medical.chief_compliant.response_to_treatment != undefined) {
          this.chief_compliant.response_to_treatment =
            val.medical.chief_compliant.response_to_treatment
        }
      }
    }
  },
  methods: {
    newAnimalUnit(val) {
      this.new_animal = val
    },

    feedUnit(val) {
      this.feed = val
    },

    staffUnit(val) {
      this.staff = val
    },

    saveDoc() {
      var chief_compliant = {
        id: this.report_id,
        data: this.chief_compliant.date,
        number_of_animals: this.chief_compliant.number_of_animals,
        number_of_sick_animals: this.chief_compliant.number_of_sick_animals,
        number_of_dead_animals: this.chief_compliant.number_of_dead_animals,
        new_animal: this.chief_compliant.new_animal,
        feed: this.chief_compliant.feed,
        staff: this.chief_compliant.staff,
        management_or_prev_treatment:
          this.chief_compliant.management_or_prev_treatment,
        response_to_treatment: this.chief_compliant.response_to_treatment
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(
            'medical_chief_compliant/update',
            chief_compliant
          )
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report_from_db != undefined &&
      this.report_from_db.medical != undefined &&
      this.report_from_db.medical.chief_compliant != undefined
    ) {
      if (this.report_from_db.medical.chief_compliant.data != undefined) {
        this.chief_compliant.date =
          this.report_from_db.medical.chief_compliant.data
      }

      if (
        this.report_from_db.medical.chief_compliant.number_of_animals !=
        undefined
      ) {
        this.chief_compliant.number_of_animals =
          this.report_from_db.medical.chief_compliant.number_of_animals
      }

      if (
        this.report_from_db.medical.chief_compliant.number_of_sick_animals !=
        undefined
      ) {
        this.chief_compliant.number_of_sick_animals =
          this.report_from_db.medical.chief_compliant.number_of_sick_animals
      }

      if (
        this.report_from_db.medical.chief_compliant.number_of_dead_animals !=
        undefined
      ) {
        this.chief_compliant.number_of_dead_animals =
          this.report_from_db.medical.chief_compliant.number_of_dead_animals
      }

      if (this.report_from_db.medical.chief_compliant.new_animal != undefined) {
        this.chief_compliant.new_animal =
          this.report_from_db.medical.chief_compliant.new_animal
        this.new_animal_unit_from_db =
          this.report_from_db.medical.chief_compliant.new_animal
      }

      if (this.report_from_db.medical.chief_compliant.feed != undefined) {
        this.chief_compliant.feed =
          this.report_from_db.medical.chief_compliant.feed
        this.feed_unit_from_db =
          this.report_from_db.medical.chief_compliant.feed
      }

      if (this.report_from_db.medical.chief_compliant.staff != undefined) {
        this.chief_compliant.staff =
          this.report_from_db.medical.chief_compliant.staff
        this.staff_unit_from_db =
          this.report_from_db.medical.chief_compliant.staff
      }

      if (
        this.report_from_db.medical.chief_compliant
          .management_or_prev_treatment != undefined
      ) {
        this.chief_compliant.management_or_prev_treatment =
          this.report_from_db.medical.chief_compliant.management_or_prev_treatment
      }

      if (
        this.report_from_db.medical.chief_compliant.response_to_treatment !=
        undefined
      ) {
        this.chief_compliant.response_to_treatment =
          this.report_from_db.medical.chief_compliant.response_to_treatment
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
