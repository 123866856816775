<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-2/3 w-full mx-auto z-30">
          <section class="px-6 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div class="float-left cursor-pointer text-xl">
              Diagnosis Report
            </div>

            <div
              @click="$emit('close-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>

            <section class="py-20 space-y-6 sm:w-4/5 sm:mx-auto">
              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-start">
                <div class="col-span-2">
                  <input-diagnosis
                    v-on:add-unit="diagnosisOne"
                    :unit_from_db="diagnosis_one_from_db"
                  ></input-diagnosis>
                </div>

                <div class="col-span-2">
                  <textarea
                    v-model="diagnosis.diagnosis_one_text"
                    cols="30"
                    rows="3"
                    placeholder="Add Note"
                    class="
                      w-full
                      border border-gray-300
                      px-4
                      py-2
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                  ></textarea>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-start">
                <div class="col-span-2">
                  <input-diagnosis
                    v-on:add-unit="diagnosisTwo"
                    :unit_from_db="diagnosis_two_from_db"
                  ></input-diagnosis>
                </div>

                <div class="col-span-2">
                  <textarea
                    v-model="diagnosis.diagnosis_two_text"
                    cols="30"
                    rows="3"
                    placeholder="Add Note"
                    class="
                      w-full
                      border border-gray-300
                      px-4
                      py-2
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                  ></textarea>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-start">
                <div class="col-span-2">
                  <input-diagnosis
                    v-on:add-unit="diagnosisThree"
                    :unit_from_db="diagnosis_three_from_db"
                  ></input-diagnosis>
                </div>

                <div class="col-span-2">
                  <textarea
                    v-model="diagnosis.diagnosis_three_text"
                    cols="30"
                    rows="3"
                    placeholder="Add Note"
                    class="
                      w-full
                      border border-gray-300
                      px-4
                      py-2
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                  ></textarea>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-start">
                <div class="col-span-2">
                  <input-diagnosis
                    v-on:add-unit="diagnosisFour"
                    :unit_from_db="diagnosis_four_from_db"
                  ></input-diagnosis>
                </div>

                <div class="col-span-2">
                  <textarea
                    v-model="diagnosis.diagnosis_four_text"
                    cols="30"
                    rows="3"
                    placeholder="Add Note"
                    class="
                      w-full
                      border border-gray-300
                      px-4
                      py-2
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                  ></textarea>
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-4 sm:gap-x-6 sm:items-start">
                <div class="col-span-2">
                  <input-diagnosis
                    v-on:add-unit="diagnosisFive"
                    :unit_from_db="diagnosis_five_from_db"
                  ></input-diagnosis>
                </div>

                <div class="col-span-2">
                  <textarea
                    v-model="diagnosis.diagnosis_five_text"
                    cols="30"
                    rows="3"
                    placeholder="Add Note"
                    class="
                      w-full
                      border border-gray-300
                      px-4
                      py-2
                      outline-none
                      rounded-md
                      text-lg
                      focus:border-primary
                    "
                  ></textarea>
                </div>
              </div>
            </section>

            <section class="flex justify-center" v-if="index == 1">
              <button
                @click="saveDoc"
                class="
                  bg-primary
                  text-white
                  px-14
                  py-2
                  text-center
                  rounded
                  shadow
                  hover:shadow-xl
                "
              >
                Save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import CloseIcon from '../../Icons/closeIcon.vue'
import InputDiagnosis from '../../Utilities/inputDiagnosis.vue'
export default {
  components: { CloseIcon, InputDiagnosis },
  props: {
    report_id: String,

    index: [Number, String],

    report: [Object, Array]
  },

  data() {
    return {
      diagnosis: {
        diagnosis_one: null,
        diagnosis_two: null,
        diagnosis_three: null,
        diagnosis_four: null,
        diagnosis_five: null,
        diagnosis_one_text: null,
        diagnosis_two_text: null,
        diagnosis_three_text: null,
        diagnosis_four_text: null,
        diagnosis_five_text: null
      },
      diagnosis_one_from_db: null,
      diagnosis_two_from_db: null,
      diagnosis_three_from_db: null,
      diagnosis_four_from_db: null,
      diagnosis_five_from_db: null,
      report_from_db: this.report
    }
  },

  watch: {
    diagnosis_one(val) {
      if (val == '') {
        this.diagnosis.diagnosis_one = null
        this.diagnosis_one_from_db = null
      }
    },

    diagnosis_two(val) {
      if (val == '') {
        this.diagnosis.diagnosis_two = null
        this.diagnosis_two_from_db = null
      }
    },

    diagnosis_three(val) {
      if (val == '') {
        this.diagnosis.diagnosis_three = null
        this.diagnosis_three_from_db = null
      }
    },

    diagnosis_four(val) {
      if (val == '') {
        this.diagnosis.diagnosis_four = null
        this.diagnosis_four_from_db = null
      }
    },

    diagnosis_five(val) {
      if (val == '') {
        this.diagnosis.diagnosis_five = null
        this.diagnosis_five_from_db = null
      }
    },

    report_from_db(val) {
      if (
        val != undefined &&
        val.medical != undefined &&
        val.medical.diagnosis != undefined
      ) {
        if (val.medical.diagnosis.diagnosis_one != undefined) {
          this.diagnosis.diagnosis_one = val.medical.diagnosis.diagnosis_one
          this.diagnosis_one_from_db = val.medical.diagnosis.diagnosis_one
        }

        if (val.medical.diagnosis.diagnosis_one_text != undefined) {
          this.diagnosis.diagnosis_one_text =
            val.medical.diagnosis.diagnosis_one_text
        }

        if (val.medical.diagnosis.diagnosis_two != undefined) {
          this.diagnosis.diagnosis_two = val.medical.diagnosis.diagnosis_two
          this.diagnosis_two_from_db = val.medical.diagnosis.diagnosis_two
        }

        if (val.medical.diagnosis.diagnosis_two_text != undefined) {
          this.diagnosis.diagnosis_two_text =
            val.medical.diagnosis.diagnosis_two_text
        }

        if (val.medical.diagnosis.diagnosis_three != undefined) {
          this.diagnosis.diagnosis_three = val.medical.diagnosis.diagnosis_three
          this.diagnosis_three_from_db = val.medical.diagnosis.diagnosis_three
        }

        if (val.medical.diagnosis.diagnosis_three_text != undefined) {
          this.diagnosis.diagnosis_three_text =
            val.medical.diagnosis.diagnosis_three_text
        }

        if (val.medical.diagnosis.diagnosis_four != undefined) {
          this.diagnosis.diagnosis_four = val.medical.diagnosis.diagnosis_four
          this.diagnosis_four_from_db = val.medical.diagnosis.diagnosis_four
        }

        if (val.medical.diagnosis.diagnosis_four_text != undefined) {
          this.diagnosis.diagnosis_four_text =
            val.medical.diagnosis.diagnosis_four_text
        }

        if (val.medical.diagnosis.diagnosis_five != undefined) {
          this.diagnosis.diagnosis_five = val.medical.diagnosis.diagnosis_five
          this.diagnosis_five_from_db = val.medical.diagnosis.diagnosis_five
        }

        if (val.medical.diagnosis.diagnosis_five_text != undefined) {
          this.diagnosis.diagnosis_five_text =
            val.medical.diagnosis.diagnosis_five_text
        }
      }
    }
  },

  methods: {
    diagnosisOne(val) {
      if (val != undefined) {
        this.diagnosis.diagnosis_one = val
      }
    },

    diagnosisTwo(val) {
      if (val != undefined) {
        this.diagnosis.diagnosis_two = val
      }
    },

    diagnosisThree(val) {
      if (val != undefined) {
        this.diagnosis.diagnosis_three = val
      }
    },

    diagnosisFour(val) {
      if (val != undefined) {
        this.diagnosis.diagnosis_four = val
      }
    },

    diagnosisFive(val) {
      if (val != undefined) {
        this.diagnosis.diagnosis_five = val
      }
    },

    saveDoc() {
      var value = {
        id: this.report_id,
        diagnosis_one: this.diagnosis.diagnosis_one,
        diagnosis_two: this.diagnosis.diagnosis_two,
        diagnosis_three: this.diagnosis.diagnosis_three,
        diagnosis_four: this.diagnosis.diagnosis_four,
        diagnosis_five: this.diagnosis.diagnosis_five,
        diagnosis_one_text: this.diagnosis.diagnosis_one_text,
        diagnosis_two_text: this.diagnosis.diagnosis_two_text,
        diagnosis_three_text: this.diagnosis.diagnosis_three_text,
        diagnosis_four_text: this.diagnosis.diagnosis_four_text,
        diagnosis_five_text: this.diagnosis.diagnosis_five_text
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('medical_diagnosis/update', value)
          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 1000
              })
              this.$emit('close-modal')
            }
          })
        }
      })
    }
  },

  mounted() {
    if (
      this.report_from_db != undefined &&
      this.report_from_db.medical != undefined &&
      this.report_from_db.medical.diagnosis != undefined
    ) {
      if (this.report_from_db.medical.diagnosis.diagnosis_one != undefined) {
        this.diagnosis.diagnosis_one =
          this.report_from_db.medical.diagnosis.diagnosis_one
        this.diagnosis_one_from_db =
          this.report_from_db.medical.diagnosis.diagnosis_one
      }

      if (
        this.report_from_db.medical.diagnosis.diagnosis_one_text != undefined
      ) {
        this.diagnosis.diagnosis_one_text =
          this.report_from_db.medical.diagnosis.diagnosis_one_text
      }

      if (this.report_from_db.medical.diagnosis.diagnosis_two != undefined) {
        this.diagnosis.diagnosis_two =
          this.report_from_db.medical.diagnosis.diagnosis_two
        this.diagnosis_two_from_db =
          this.report_from_db.medical.diagnosis.diagnosis_two
      }

      if (
        this.report_from_db.medical.diagnosis.diagnosis_two_text != undefined
      ) {
        this.diagnosis.diagnosis_two_text =
          this.report_from_db.medical.diagnosis.diagnosis_two_text
      }

      if (this.report_from_db.medical.diagnosis.diagnosis_three != undefined) {
        this.diagnosis.diagnosis_three =
          this.report_from_db.medical.diagnosis.diagnosis_three
        this.diagnosis_three_from_db =
          this.report_from_db.medical.diagnosis.diagnosis_three
      }

      if (
        this.report_from_db.medical.diagnosis.diagnosis_three_text != undefined
      ) {
        this.diagnosis.diagnosis_three_text =
          this.report_from_db.medical.diagnosis.diagnosis_three_text
      }

      if (this.report_from_db.medical.diagnosis.diagnosis_four != undefined) {
        this.diagnosis.diagnosis_four =
          this.report_from_db.medical.diagnosis.diagnosis_four
        this.diagnosis_four_from_db =
          this.report_from_db.medical.diagnosis.diagnosis_four
      }

      if (
        this.report_from_db.medical.diagnosis.diagnosis_four_text != undefined
      ) {
        this.diagnosis.diagnosis_four_text =
          this.report_from_db.medical.diagnosis.diagnosis_four_text
      }

      if (this.report_from_db.medical.diagnosis.diagnosis_five != undefined) {
        this.diagnosis.diagnosis_five =
          this.report_from_db.medical.diagnosis.diagnosis_five
        this.diagnosis_five_from_db =
          this.report_from_db.medical.diagnosis.diagnosis_five
      }

      if (
        this.report_from_db.medical.diagnosis.diagnosis_five_text != undefined
      ) {
        this.diagnosis.diagnosis_five_text =
          this.report_from_db.medical.diagnosis.diagnosis_five_text
      }
    }
  }
}
</script>

<style>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
